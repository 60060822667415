import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Select, MenuItem, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { BODY_PARTS, Options, skillLevelOptions } from "../../utils/fixtures";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '../atoms/Alert'
import SearchExercise from "./SearchExercise";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ImageFieldWithSearch from "./ImageFieldWithSearch";
import VideoFieldWithSearch from "./VideoFieldWithSearch";

const MUTATION_SESSION = gql`
  mutation AddSession($input: NewSessionInput!) {
  addSession(input: $input) {
    id
    title
  }
}
`

const QUERY_EXERCISES = gql`
  query Exercises {
  exercises {
    id
    title
  }
}
`

type ExercisesOrGroups = 'exercises' | 'groups'

// create a react context object
const ExerciseOptionsContext = React.createContext([] as Options)

const CreateSession = ()=> {
  const { data : myExercisesData, loading : myExercisesLoading, error: myExercisesError } = useQuery(QUERY_EXERCISES, {
    variables: { "input": { creatorId : getCurrentUser().id } }
  })
  const { data : publicExercisesData, loading : publicExercisesLoading, error: publicExercisesError } = useQuery(QUERY_EXERCISES, {
    variables: { "input": { private : false } }
  })

  const [exerciseOptions, setExerciseOptions] = useState<Options>([])

  const [toast, setToast] = useState<{open: boolean, message: string, severity: 'error' | 'success'}>({open: false, message: '', severity: 'success'})

  const targetMusclesOptions : Options = Object.keys(BODY_PARTS)?.map((limb: string )=> {
    // @ts-ignore
    return { label: limb, id: BODY_PARTS[limb] }
  })

  const [exercisesOrGroups, setExercisesOrGroups] = useState<ExercisesOrGroups>('groups')

  const [addSession, {data: sessionData, loading: sessionLoading, error: sessionError }] = useMutation(MUTATION_SESSION)

  useEffect(()=>{
    const tempOptions : {title: string, id: number}[] | []  = myExercisesData?.exercises && publicExercisesData?.exercises 
    ? [...myExercisesData?.exercises, ...publicExercisesData?.exercises]
    : []

    const dedupOptions = Array.from(new Set(tempOptions.map(a => a.id)))
    .map(id => {
      return tempOptions.find(a => a.id === id)
    })
    
    const Options: Options = []
    dedupOptions.forEach((item : any) => {
      Options.push({
        label: item.title,
        id: item.id
      })
    })

    setExerciseOptions(Options)
  }, [myExercisesData, publicExercisesData])

  const { register, setValue, handleSubmit, getValues, control } = useForm({
    defaultValues: {
      "title": '',
      "description": '',
      "image": '',
      "video": '',
      "groups": [
        {
          "title": '',
          "reps": '',
          "exerciseInstruction": [
            {
              "exercise": '',
              "instruction": ''
            }
          ]
        }
      ],
      "targetMuscles": [null],
      "skillLevel": 'ANY',
      "duration": 0,
      "trainer": '',
      "equipment": '',
      "private": false,
    }
  });

  const [groupCount, setGroupCount] = useState(1)
  const [showImageSearch, setShowImageSearch] = useState(false)
  const [sessionImage, setSessionImage] = useState(getValues('image'))

  
  return (
    <ExerciseOptionsContext.Provider value={exerciseOptions}>
      <div style={{background: 'whitesmoke', height: '100vh'}}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={toast.open} autoHideDuration={6000} onClose={()=>{}}>
            <Alert onClose={()=>{ setToast({...toast, open: false})}} severity={toast.severity} sx={{ width: '100%' }}>
              {toast.message}
            </Alert>
          </Snackbar>
        </Stack>

        <Box sx={{ margin: '24px', backgroundColor: 'white', padding: '12px', borderRadius: '12px'}}>
          <Box sx={{display: 'flex'}}>
            <Typography  sx={{margin: '12px 0'}} variant="h5" component="div">
              New Session
            </Typography>
            <Box sx={{display: "flex", flex: 1, justifyContent: 'flex-end', alignItems: 'center',  paddingRight: '12px'}}>
            </Box>
          </Box>

          <Divider/>

          <Box 
            sx={{display:'flex', gap: '12px', marginTop: '12px', flexDirection: 'column'}}
            component="form"
            onSubmit={handleSubmit((data => {
              console.log('data', data)

              addSession({
                variables: {
                  "input": {
                    ...data,
                    groups: exercisesOrGroups === 'groups' ? data.groups : null,
                  }
                }
              })
              .then(({data})=>{
                console.log('success heres your session - ', data)
                setToast({open: true, message: 'Session created successfully', severity:"success"})
              })
              .catch((e)=>{
                console.log(e)
                setToast({open: true, message: 'Error creating session, try again.', severity:"error"})
              })
            }))}
            >
            <TextField
              required
              id="title"
              label="Title"
              placeholder="title" 
              {...register("title", {required: true})}
            />
            <TextField
              required
              id="description"
              label="Description"
              placeholder="description" 
              {...register("description", {required: true})}
            />

            <ImageFieldWithSearch imageUrl={getValues('image')} onChange={(val)=> { setValue("image", val) }} />
            <VideoFieldWithSearch videoUrl={getValues('image')} onChange={(val)=> { setValue("video", val) }} />

            <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
              Target muscles
            </Typography>
            <Controller
              control={control}
              name="targetMuscles"
              rules={{ required: 'true' }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  multiple
                  disablePortal
                  id="targetMuscles"
                  options={targetMusclesOptions}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Select target muscles" />}
                  onChange={(d, val)=>{ 
                    console.log('change targetMuscles', val)
                    // @ts-ignore
                    setValue('targetMuscles', val.map((item: any)=>item.id))
                  }}
                />
              )}
            />

            <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
              Skill Level
            </Typography>
            <Controller
              control={control}
              name="skillLevel"
              rules={{ required: 'true' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="skillLevel-select-label"
                  id="skillLevel"
                  value={getValues('skillLevel')}
                  label="skill level"
                  onChange={(e)=>{ 
                    console.log('change skillLevel', e)
                    // @ts-ignore
                    setValue('skillLevel', e?.target?.value)
                  }}
                  required={true}
                >
                  {skillLevelOptions.map((skill)=>{
                    return <MenuItem value={skill.id} key={skill.id}>{skill.label}</MenuItem>
                  })}
                </Select>
              )}
            />

            <TextField
              required
              id="duration"
              label="Duration in minutes"
              placeholder="duration" 
              type="number"
              onChange={(e)=>{ 
                console.log('change duration', e)
                // @ts-ignore
                setValue('duration', Number(e?.target?.value))
              }}
            />
            <TextField
              id="trainer"
              label="Trainer"
              placeholder="trainer" 
              {...register("trainer")}
            />
            <TextField
              id="equipment"
              label="Equipment"
              placeholder="dumbells, kettlebells, etc." 
              {...register("equipment")}
            />

            {/* exercises or groups */}
            <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
              Create exercise groups/ sets
            </Typography>
            {/* <ToggleButtonGroup
              sx={{ padding: '12px 0 0'}}
              color="primary"
              value={exercisesOrGroups}
              exclusive
              onChange={(e, val)=>{ setExercisesOrGroups(val)}}
              aria-label="exercises or groups"
            >
              <ToggleButton value="exercises" aria-label="exercise">
                Exercises
              </ToggleButton>
              <ToggleButton value="groups" aria-label="groups or sets">
                Groups/ Sets
              </ToggleButton>
            </ToggleButtonGroup> */}

              {/* Groups */}
              <>
                {getValues('groups').map((values, index)=>{
                  return <CreateGroups 
                  key={uuidv4()}  
                  index={index} 
                  values={values}
                  // exerciseOptions={exerciseOptionsContext}
                  onChange={
                    (values: any)=>{
                      const tempGroups = getValues('groups')
                      tempGroups[index] = values
                      setValue('groups', tempGroups)
                    }
                  }
                  showDelete={groupCount > 1}
                  onDelete={
                  (val: number) => {
                    // get the current group
                    // then remove the item at the index
                    // then save the new array to the state
                    const currentGroup = getValues("groups")
                    currentGroup.splice(val, 1)
                    setValue('groups', currentGroup)

                    //hacky - but it triggers a re-render
                    setGroupCount(currentGroup.length)

                    console.log('delete group', val, currentGroup)
                  }
                }
                  />
                }) 
                }
                <Button onClick={()=>{
                  const tempGroups = getValues('groups')
                  tempGroups.push({
                    "title": '',
                    "reps": '',
                    "exerciseInstruction": [
                      {
                        "exercise": '',
                        "instruction": ''
                      }
                    ]
                  })
                  setValue('groups', tempGroups)

                  // hacky way to re-render
                  setGroupCount(tempGroups.length)
                }}>Add group</Button>
              </>
            

            <FormControlLabel control={
              <CheckBox
                value={getValues('private')}
                id="private"
                onChange={(e)=>{ setValue('private', e.target.checked) }}
              />} 
              label="Private"
            />

            <Box>
              <Button variant="contained" type="submit">Save</Button>
              <Button variant="contained" color="error">Cancel</Button>
            </Box>
          </Box>
        </Box>
      </div>
    </ExerciseOptionsContext.Provider>
  )
}

export default CreateSession

interface CreateGroupsProps {
  index: number
  onChange: (groups: any)=>void
  showDelete?: boolean
  onDelete: (val: number) => void
  values: any,
  exerciseOptions?: any
}

const CreateGroups = ({onChange, index, showDelete, onDelete, values, exerciseOptions}: CreateGroupsProps) => {
  const [exerciseInstructionCount, setExerciseInstructionCount] = useState(1)
  const { register, handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: !!values ? values : {
      title: '',
      reps: '',
      exerciseInstruction: [
        {
          exercise: '',
          instruction: ''
        }
      ],
    }
  });

  const exerciseOptionsContext = React.useContext(ExerciseOptionsContext)

  return (
    <Box sx={{display:'flex', gap: '12px', marginTop: '12px', flexDirection: 'column', background: '#d3d3d354', padding: '16px', borderRadius: '10px'}}
    onSubmit={handleSubmit((val)=> { console.log('sub group - ', val)})}>
      <TextField
        required
        id="title"
        label="Title"
        placeholder="title"
        {...register("title", {required: true, onChange() {
          onChange(getValues())
        }})}
      />
      <TextField
        required
        id="reps"
        label="Reps"
        placeholder="reps" 
        {...register("reps", {required: true, onChange() {
          onChange(getValues())
        }})}
      />

      <Box sx={{display: 'flex'}}>
        <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
          Exercise and Instruction
        </Typography>
        <Button onClick={()=>{
          // get the current exerciseInstruction array
          // then add a new item to the array
          // then save the new array to the state
          const currentExerciseInstruction = getValues("exerciseInstruction")
          currentExerciseInstruction.push({exercise: '', instruction: ''})
          setValue('exerciseInstruction', currentExerciseInstruction)

          //hacky - but it triggers a re-render
          setExerciseInstructionCount(currentExerciseInstruction.length)
          console.log('add another', currentExerciseInstruction)
          }}>
            Add another
        </Button>
      </Box>
      
      {/* @ts-ignore */}
      {getValues("exerciseInstruction").map((item, index)=> {
        return <ExerciseInstruction 
          key={uuidv4()}  
          index={index} 
          exerciseOptions={exerciseOptionsContext}
          exercise={item.exercise}
          instruction={item.instruction}
          onChange={
            ({exercise, instruction}) => {
              // get the current exerciseInstruction array
              // then update the item at the index
              // then save the new array to the state
              const currentExerciseInstruction = getValues("exerciseInstruction")
              currentExerciseInstruction[index] = {exercise, instruction}
              
              setValue('exerciseInstruction', currentExerciseInstruction)
              onChange(getValues())
            }
          }
          showDelete={exerciseInstructionCount > 1}
          onDelete={
          (val: number) => {
            // get the current exerciseInstruction array
            // then remove the item at the index
            // then save the new array to the state
            const currentExerciseInstruction = getValues("exerciseInstruction")
            currentExerciseInstruction.splice(val, 1)
            setValue('exerciseInstruction', currentExerciseInstruction)

            //hacky - but it triggers a re-render
            setExerciseInstructionCount(currentExerciseInstruction.length)

            console.log('delete', val, currentExerciseInstruction)
          }
        }/>
      }
      )}
      {showDelete ? <Button onClick={()=> {onDelete(index)}}>Delete</Button> : null}

    </Box>
  )
}

/// create component for the exercise and instruction
interface ExerciseInstructionProps {
  index: number,
  exerciseOptions?: any,
  exercise?: string,
  instruction?: string
  showDelete?: boolean
  onDelete: (val: number) => void
  onChange: ({exercise, instruction}: {exercise: string, instruction: string}) => void
}

const ExerciseInstruction = ({onDelete, onChange, exerciseOptions, index, exercise, instruction, showDelete=true} : ExerciseInstructionProps) => {
  const [showExerciseSearchComponent, setShowExerciseSearchComponent] = useState(false)
  const { register, handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      exercise: !!exercise ? exercise : exerciseOptions[0]?.id,
      instruction: ''
    }
  });

   // get ExerciseOptionsContext value
  const exerciseOptionsContext = React.useContext(ExerciseOptionsContext)

  return <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box sx={{display: "flex"}}>
      {!showExerciseSearchComponent ? 
        <Box sx={{display: "flex"}}>
          <Controller
              control={control}
              name="exercise"
              rules={{ required: 'true' }}
              render={() => (
                <Select
                  labelId="exercise-select-label"
                  id="exercise"
                  value={getValues('exercise')}
                  label="exercise"
                  onChange={(e)=>{ 
                    console.log('change exercise', e?.target?.value)
                    // @ts-ignore
                    setValue('exercise', e?.target?.value)
      
                    onChange({...getValues(), exercise: e?.target?.value})
                  }}
                  required={true}
                >
                  {/* @ts-ignore */}
                  {exerciseOptions.map((exercise)=>{
                    return <MenuItem value={exercise.id} key={exercise.id}>{exercise.label}</MenuItem>
                  })}
                </Select>
              )}
            />
            <Button onClick={()=>{setShowExerciseSearchComponent(true)}} variant="outlined"><SearchIcon/></Button> 
          </Box>
         : null}
        {/* search exercise component */}
        {showExerciseSearchComponent ? 
        <SearchExercise 
          onChange={(id, label)=>{ 
            setValue('exercise', id)
            onChange({...getValues(), exercise: id})
            
            exerciseOptionsContext.push({ label, id })
            setShowExerciseSearchComponent(false)
          }}
          onClose={()=>setShowExerciseSearchComponent(false)}
        /> : null}

      </Box>
      <TextareaAutosize
        minRows={4}
        required
        id="Instruction"
        placeholder="Instruction - eg: 3 sets of 10 reps"
        defaultValue={instruction}
        style={{
          border: '1px solid rgb(192 192 192)',
          borderRadius: '4px',
          backgroundColor: 'initial'
        }}
        onChange={(e)=>{
          // @ts-ignore
          onChange({... getValues(), instruction: e?.target?.value})
        }}
      />
      {showDelete ? <Button onClick={()=>{onDelete(index)}} variant="outlined"><DeleteIcon/></Button> : null}
      
  </Box>
  
}