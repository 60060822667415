import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/joy/Chip';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import Divider from '@mui/material/Divider';
import { Button } from "@mui/material";
import Timer from '../molecules/Timer'
import BodyPartsForm from "../organisms/AddBodyLogForm";
import AddLogForm from "../organisms/AddLogForm";
import {getCurrentUser} from '../../services'
import { QUERY_PROGRAM_PROGRESS, QUERY_MY_PROGRAM } from "../pages/Program";
import { MUTATION_ACTIVITY_LOG } from "../../utils/queries";
import { ACTIVITY } from "../../utils/fixtures";
import Layout from "../organisms/Layout";
import Loader from "../atoms/Loader";
import Toast from '../molecules/Toast'

import ShareIcon from '@mui/icons-material/Share';

const QUERY_MY_SESSION = gql`
  query Session($input: SessionInput) {
  sessions(input: $input) {
    id
    title
    groups {
      title
      reps
      exerciseInstruction {
        exercise {
          id
          title
          description
          image
          video
        }
        instruction
      }
    }
    targetMuscles
    skillLevel
    description
    duration
    equipment
    image
    video
  }
}
`

const MUTATION_UPSERT_PROGRAM_PROCESS = gql`
  mutation UpsertProgramProgress($input: UpsertProgramProgressInput!) {
    addProgramProgress(input: $input) {
      id
    }
  }
  `

const Session = ({ })=> {
  const [toast, setToast] = React.useState<{open: boolean, message: string, severity: 'error' | 'success'}>({
    open: false, 
    message: '', 
    severity: 'success'
  })
  const [state, setSate] = useState<"viewSession" | "inWorkout" | "preworkout">("viewSession")
  const [activeGroup, setActiveGroup] = useState<number>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [bodyLogOpen, setBodyLogOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [exerciseInfo, setExerciseInfo] = useState(null)
  const { id } = useParams()
  const { data, loading, error } = useQuery(QUERY_MY_SESSION, {
    variables: { "input": { id } }
  })
  const programId = new URLSearchParams(window.location.search).get('programId')
  const { data: progressData, loading: progressLoading, error: progressError } = useQuery(QUERY_PROGRAM_PROGRESS, {
    variables: { "input": {
      "completed": false,
      "userId": getCurrentUser()?.id,
      "programId": programId,
    }}
  })
  const { data: programData, loading: programLoading, error: programError } = useQuery(QUERY_MY_PROGRAM, {
    variables: { "input": { id: programId } }
  })
  const [addProgramProgress] = useMutation(MUTATION_UPSERT_PROGRAM_PROCESS)
  const [addActivityLog] = useMutation(MUTATION_ACTIVITY_LOG)
  const navigate = useNavigate()
  const ref = useRef<any>(null)
  const activeGroupExerciseColor = "#bae1f1"
  const isUserLoggedIn = !!getCurrentUser()

  React.useEffect(() => {
    document.title = 'Serious Trainer | Session';
  }, []);

  React.useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    let title = document.querySelector("meta[name='title']");
    let description = document.querySelector("meta[name='description']");

    let ogUrl= document.querySelector("meta[property='og:url']");
    let ogTitle = document.querySelector("meta[property='og:title']");
    let ogDescription = document.querySelector("meta[property='og:description']");
    let ogImage = document.querySelector("meta[property='og:image']");

    if (!link) {
      link = document.createElement('link');
      // @ts-ignore
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if(data?.sessions[0]){
      // @ts-ignore
      link.href = data?.sessions[0]?.image;
      title?.setAttribute("content", data?.sessions[0]?.title);
      description?.setAttribute("content", data?.sessions[0]?.description);
      
      ogUrl?.setAttribute("content", window.location.href);
      ogTitle?.setAttribute("content", data?.sessions[0]?.title);
      ogDescription?.setAttribute("content", data?.sessions[0]?.description);
      ogImage?.setAttribute("content", data?.sessions[0]?.image);


    }
  }, [data]);

  useEffect(() => {
    if(state === "preworkout") {
      setTimeout(() => {
        setSate("inWorkout")
      }, 1000);
    }
  
    
    if(state === "inWorkout" && ref.current) {
      // scroll to first exercise group
      const bodyRect = document.body.getBoundingClientRect()
      const elemRect = ref.current.getBoundingClientRect()
      const offset   = elemRect.top - bodyRect.top
      const padding = 90

      window.scrollTo(0, offset-padding)

      //change first exercise group bkg color
      setActiveGroup(0) 
    }

  }, [state])
  
  const handleActionButton = (workoutDuration?: number)=>{
    if(!isUserLoggedIn) { return navigate('/login') }

    if (state === "viewSession") {
      setSate('preworkout')
    }
    else {
      setBodyLogOpen(true)

      //TODO: save progress
      const programProgress = progressData.programProgress[0]
      const completionCriteria = programProgress?.completionCriteria
      const sessionsCompleted = programProgress?.sessionsCompleted ?? []
      if (!sessionsCompleted?.includes(data?.sessions[0]?.id)) {
        sessionsCompleted.push(data?.sessions[0]?.id)
      }
      // TODO: - default completion == complete session once && implement completion criteria
      const completed = sessionsCompleted?.length ? sessionsCompleted?.length === programData?.programs[0]?.sessions?.length : false

      addProgramProgress({
        variables: {
          "input": {
            "userId": getCurrentUser().id,
            programId,
            completed,
            sessionsCompleted,
            completionCriteria
          }
        }
      })
      const duration = workoutDuration as number >= 1 ? workoutDuration : 1 
      addActivityLog({
        variables: {
          "input": {
            "activity": ACTIVITY.session,
            "activityID": id,
            "title": data?.sessions[0]?.title,
            "description": data?.sessions[0]?.description,
            "metaData": JSON.stringify({ image: data?.sessions[0]?.image, duration, programTitle: programData?.programs[0]?.title ?? "" }),
          }
        }
      })
    }
  }

  const returnToProgram = ()=>{ 
    //TODO: post session data to program/ profile
    navigate(-1) 
  }

  if (error) ( <p>error</p> )

  if (state == "preworkout") {
    return ( <Box sx={{width: "100vw", height:"100vh", backgroundColor: "black", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
      Lets Go!
    </Typography>
  </Box> )}

  return (
   <Layout>
    <Toast toast={toast} setToast={setToast} />
      <Box sx={{background: 'whitesmoke', height:'100%'}}>
        {state === "inWorkout" ? <Timer onEnd={handleActionButton} pauseTimer={bodyLogOpen} /> : null}
  
        <Box>
          {loading ? <Loader /> : null}

          <Box sx={{display: 'flex', height: '70vh', backgroundImage:`url(${data?.sessions[0]?.image})`, backgroundSize: 'cover', backgroundColor:'darkgray', flexDirection: 'column', justifyContent: 'end', padding: '12px 24px'}}>
            <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
              {data?.sessions[0]?.title}
            </Typography>
  
            <Box sx={{display: "flex"}}>
              {state ==="viewSession" ? <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#ff3c4b", cursor: 'pointer'}}
               onClick={()=>handleActionButton(0)}
               startDecorator={<PlayCircleFilledOutlinedIcon/>} >
                Start!
              </Chip>
              : <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#ff3c4b"}}
              startDecorator={<FastForwardOutlinedIcon/>} >
               Get it!
             </Chip>
              }

              {/* create share button to copy link from address bar to clip board  */}
              <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#000", marginLeft: '12px'}}
              onClick={()=>{ 
                navigator.clipboard.writeText(window.location.href)
                
                setToast({open: true, message: 'Link copied to clipboard', severity: 'success'})
              }}
              startDecorator={<ShareIcon/>}
              >
                Share
              </Chip>
            </Box>
          </Box>
  
          <Typography sx={{margin: ' 24px'}} variant="body1" component="div">
            {data?.sessions[0]?.description}
          </Typography>
  
          {/* stats */}
          <Box sx={{ margin: '24px' }}>
            <Box sx={{ display: 'flex', }}>
              <Box sx={{display: 'flex', alignItems: 'center', width: '300px'}}>
                <AccessAlarmsOutlinedIcon/>
                <Typography  sx={{margin: '12px'}} variant="body1">
                  {data?.sessions[0]?.duration} min
                </Typography>
              </Box>
    
              <Box sx={{display: 'flex', alignItems: 'center', width: '300px'}}>
                <DirectionsRunOutlinedIcon/>
                <Typography  sx={{margin: '12px'}} variant="body1">
                  skill level: {data?.sessions[0]?.skillLevel.toLowerCase()}
                </Typography>
              </Box>
            </Box>
  
            <Box sx={{ display: 'flex' }}>
              <Box sx={{display: 'flex', alignItems: 'center', width: '300px'}}>
                <FitnessCenterOutlinedIcon/>
                <Typography  sx={{margin: '12px'}} variant="body1">
                  equipment: {data?.sessions[0]?.equipment}
                </Typography>
              </Box>
    
              <Box sx={{display: 'flex', alignItems: 'center', width: '300px'}}>
                <AccessibilityNewOutlinedIcon/>
                <Typography  sx={{margin: '12px'}} variant="body1">
                  target muscles: {data?.sessions[0]?.targetMuscles?.join(', ')}
                </Typography>
              </Box>
            </Box>
          </Box>
        
         <Box ref={ref}>
           {/* groups with sessions */}
           {data?.sessions[0]?.groups?.length && data?.sessions[0]?.groups?.map( (group: any, index: number)  => {
              const defaultBkgColor = "#ededed"
              const groupBkgColor = activeGroup === index ? activeGroupExerciseColor : defaultBkgColor
  
              return <Box key={`${group.title}-${index}`} sx={{ margin: '24px', backgroundColor: groupBkgColor, padding: '12px', borderRadius: '12px'}} 
              onClick={()=> { 
                if (state === "inWorkout") {
                  setActiveGroup(index) 
                }
              }}
              >
                 
                {loading ? <Box>
                  <Box sx={{height: '30px', width: '200px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
                  <Box sx={{height: '300px', width: '100%', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
                  </Box> : null}

                <Box sx={{display: 'flex'}}>
                  <Typography  sx={{margin: '12px 0'}} variant="h5" component="div">
                    {group.title}
                  </Typography>
                  <Box sx={{display: "flex", flex: 1, justifyContent: 'flex-end', alignItems: 'center',  paddingRight: '12px'}}>
                    <RestartAltOutlinedIcon/>
                    <Typography  sx={{margin: '12px 0'}} variant="h6" component="div">
                      {group.reps}x
                    </Typography>
                  </Box>
                </Box>
    
                <Divider/>
    
                {group?.exerciseInstruction && group?.exerciseInstruction.map(({exercise, instruction}: any, index: number) => {
                return <Box 
                        key={`${group.title}-${exercise?.id}-${index}`} sx={{display: 'flex', flexDirection: 'column', marginY: '24px'}} 
                        onClick={()=>{}} >
                  <Box className="flex-container">
                    <Box className="exercise-thumbnail" style={{height: '50px', width: '50px', display: 'flex', marginRight: '12px', backgroundColor: 'lightgrey'}}>
                      {exercise?.image && <img className="exercise-thumbnail" alt="exercise-img" src={exercise?.image}/>}
                    </Box>
                    <Box className="exercise-title-instructions">
                      <Typography variant="body1" component="div" fontWeight="bold" fontSize="14px">
                        {exercise?.title}
                      </Typography>
                      <Typography variant="body1" component="div" fontSize="14px" sx={{textTransform: 'lowercase'}}>
                        {instruction}
                      </Typography>
                    </Box>
            
                    <Box sx={{display: "flex", flex: 1, justifyContent: 'flex-end', alignItems: 'center',  paddingRight: '12px'}}>
                      {/* TODO: ADD LATER */}
                      {/* { state === "inWorkout" && <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#ff3c4b"}} startDecorator={<RateReviewOutlinedIcon/>} >
                        Log
                      </Chip>} */}
  
                      {exercise?.description && <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#ff3c4b"}} startDecorator={<DescriptionOutlinedIcon />} 
                      onClick={()=> {
                        setVideoId(null);
  
                        if (exercise?.id === exerciseInfo) {
                          setExerciseInfo(null)
                        } else {
                          setExerciseInfo(exercise?.id)
                        }
                      }}
                      >
                        Info
                      </Chip>}
  
                      { exercise?.video && <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#ff3c4b"}} startDecorator={<VideoCameraBackOutlinedIcon/>} 
                      onClick={()=> {
                        setExerciseInfo(null);
  
                        if (exercise?.id === videoId) {
                          setVideoId(null)
                        } else {
                          setVideoId(exercise?.id)
                        }
                      }}
                      >
                        Video
                      </Chip>}
                    </Box>
                  </Box>
  
                  {/* exercise */}
                  <Box>
                    {videoId === exercise?.id ? <iframe width="100%" height="315" src={exercise?.video} style={{maxWidth: "560px", marginTop: "12px"}} title="Serious trainer player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : ''}
  
                    {exerciseInfo === exercise?.id && 
                    <Box sx={{display: 'flex', flexDirection: 'column', padding: '6px 0px 12px'}}>
                      <Typography variant="body1" component="div" fontWeight="bold" fontSize="14px">
                        More info
                      </Typography>
                      <Typography variant="body1" component="div"  fontSize="14px">
                        {exercise?.description}
                      </Typography>
                    </Box>
                    }
                    
                  </Box>
  
                  <Divider className="exercise-divider"/>
                </Box>
                })}
              </Box>
            })}
         </Box>
  
          <Box sx={{display: 'flex', justifyContent:'center', padding: '0 24px 24px'}}>
            <Button variant="contained" fullWidth={true}
           onClick={()=>handleActionButton(0)}
           color={state === "inWorkout" ? 'error': 'primary'}>{!isUserLoggedIn ? "Log in or Signup" : state === "viewSession" ? 'Begin' : 'End Workout'}</Button>
           </Box>
  
        </Box>
        
        <BodyPartsForm
          open={bodyLogOpen}
          setDialogOpen={setBodyLogOpen}
          sessionLogId={data?.sessions[0]?.id}
          btnText="next"
          onClick={()=>{setDialogOpen(true)}}
        />
  
        <AddLogForm open={dialogOpen} setDialogOpen={setDialogOpen} onClick={returnToProgram} />
      </Box>
   </Layout>
  )
}

export default Session