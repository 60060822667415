import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import EditIcon from '@mui/icons-material/Edit';
import { ACTIVITY } from "../../utils/fixtures";

interface ActivityCardProps {
  title: string
  description: string
  metaData: string
  createdAt: string
  activity: string
}
const ActivityCard = ({title, description, metaData, createdAt, activity } : ActivityCardProps) => {
  const titleAction =  activity === ACTIVITY.session ? ' - completed' : ''
  const meta = metaData && JSON.parse(metaData)

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: '10px', background: 'aliceblue', width: '250px', padding: '10px', borderRadius: '10px', boxShadow: '4px 4px 10px 0px #dae2e2', minWidth: '200px'}}>
      {/* workout completed */}
      <Box sx={{display: 'flex', justifycontent: 'center', alignItems: 'center', gap: '10px'}}>
       {activity === ACTIVITY.session && <Box sx={{backgroundColor: "green", color:'white', padding: '2px 4px', borderRadius: '50px'}}><FitnessCenterIcon sx={{ position: 'relative', top: '-2px', width: '20px', height: '14px'}} color="inherit"/></Box>}
       {activity === ACTIVITY.bodyLog && <Box sx={{backgroundColor: "green", color:'white', padding: '2px 4px', borderRadius: '50px'}}><AccessibilityNewIcon sx={{ position: 'relative', top: '-2px', width: '20px', height: '14px'}} color="inherit"/></Box> }
       {activity === ACTIVITY.sessionLog && <Box sx={{backgroundColor: "green", color:'white', padding: '2px 4px', borderRadius: '50px'}}><EditIcon sx={{ position: 'relative', top: '-2px', width: '20px', height: '14px'}} color="inherit"/></Box> }

        <Typography className="line-clamp-2" variant="h2" fontWeight={700} fontSize={14} >
          {title} {titleAction}
        </Typography>
      </Box>
      {/* img and main detail */}
      <Box sx={{display: 'flex', gap: '10px'}}>
        <Box>
          {activity === ACTIVITY.session ? 
          <img style={{borderRadius: '10px', width: '60px'}} width={'80px'} alt="activity image" src={meta.image}/> : ""}
        </Box>
        <Box>
          {/* title - eg: program */}
          {activity === ACTIVITY.session ?  <Typography className="line-clamp-2" sx={{marginBottom:'10px'}} variant="h2" fontWeight={600} fontSize={12} color={"slategrey"}>
            program: {meta.programTitle}
          </Typography> : ''}
          <Typography className="line-clamp-2" variant="h2" fontWeight={500} fontSize={12} color={"slategrey"}>
            {description}
          </Typography>
        </Box>
      </Box>
      {/* bottom panel */}
      <Box sx={{display: 'flex', gap: '10px', justifyContent: 'flex-end'}}>
        {/* col 1 */}
        <Typography variant="body1" fontSize={10} color={"slategrey"} >
          {activity === ACTIVITY.session ?  `${meta.duration} mins` : ''}
        </Typography>
        {/* date */}
        <Typography variant="body1" fontSize={10} color={"black"} fontWeight={800} >
          {new Date(+createdAt).toDateString()}
        </Typography>
      </Box>
    </Box>
  )
}

export default ActivityCard


{/* <Box sx={{backgroundColor: "#92790c", color:'white', boxShadow: '0 0 1px 3px #8a6f0f', padding: '10px', borderRadius: '8px'}}><AccessibilityNewIcon color="inherit"/></Box>  */}