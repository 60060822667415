import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'
import { useNavigate } from "react-router-dom";
import AddLogForm from "../organisms/AddLogForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Layout from "../organisms/Layout";
import Loader from "../atoms/Loader";

const QUERY_LOG_LIST = gql`
  query Logs($input: AllLogsInput) {
    logs(input: $input) {
      id
      # mood - enable in the backend
      entry
      activity
      createdAt
      type
      bodyPartData {
        bodyPart
        pain
      }   
    }
  }
`

const Delete_LOG = gql`
  mutation DeleteLog($input: Id!) {
    deleteLog(input: $input)
  }
`

const Logs = ({ })=> {
  const { data, loading, error } = useQuery(QUERY_LOG_LIST, {
    variables: { "input": { userId : getCurrentUser().id, limit: 10 } }
  })
  const [deleteLog, {data: _data, loading: _loading, error: _error}] = useMutation(Delete_LOG)
  const navigate = useNavigate()
  const [dialogOpen, setDialogOpen] = useState(false)

  React.useEffect(() => {
    document.title = 'Serious Trainer | Logs';
  }, []);

  const onLogDelete = (id) => {
    deleteLog({
      variables: {
        "input": { id }
      }
    })

    setDialogOpen(false)
  };

  const handleLogClick = (id) => {
    navigate(`/p/log/${id}`)
  }

  if(loading) ( <Loader/> )

  if (error) ( <p>error</p> )

  if (data && !data.logs.length) ( <p>no logs to show, create some :)</p>)

  return (
  <Layout>
    <div style={{background: 'whitesmoke'}}>
      <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-between', paddingRight:'30px' }}>
        <Typography variant="h5" component="div" style={{margin: '0 24px'}}>
          Logs
        </Typography>
        <AddBoxOutlinedIcon onClick={()=>{ setDialogOpen(true) }} style={{cursor:'pointer'}}/>
      </Box>  
        {data && data.logs.filter(log => log !== null ).map((log)=> {
          const date = log?.createdAt ? new Date(+log.createdAt).toDateString() : ''
  
          return (
          <Box sx={{ margin: '24px', cursor:'pointer' }} onClick={()=> { handleLogClick(log.id)}}>
            <Card>{
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {`${log?.type == "exerciseLog" ? "Session" : "Body"}`} log <Chip label={date} variant="outlined" color="primary"/>
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  </Typography>
                  <Typography variant="body2">
                  {log?.entry}
                  </Typography>
                  <br/>
  
                  <Stack direction="row" spacing={1}>
                    <Chip label={log?.mood} style={{backgroundColor:"#fae3c0", color:"#ff9604"}} />
                    <Chip label={log?.activity ?? "n😢 activity"} style={{backgroundColor:"#ffd0d3", color:"#ff3c4b"}}/>
                  </Stack>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="outlined" onClick={()=>{ onLogDelete(log?.id)}}>delete</Button>
                </CardActions>
              </React.Fragment>
            }</Card>
          </Box>
          )
        })}
  
        <AddLogForm open={dialogOpen} setDialogOpen={setDialogOpen}/>
    </div>
  </Layout>
  )
}

export default Logs