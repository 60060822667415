export const BODY_PARTS = {
  "trapezius": "trapezius",
  "upper-back": "upper_back",
  "lower-back": "lower_back",
  "chest": "chest",
  "biceps": "biceps",
  "triceps": "triceps",
  "forearm": "forearm",
  "back-deltoids": "back_deltoids",
  "front-deltoids": "front_deltoids",
  "abs": "abs",
  "obliques": "obliques",
  "adductor": "adductor",
  "hamstring": "hamstring",
  "quadriceps": "quadriceps",
  "abductors": "abductors",
  "calves": "calves",
  "gluteal": "gluteal",
  "head": "head",
  "neck": "neck",
  "knees": "knees",
}

export const ACTIVITY = {
  "session": "session",
  "bodyLog": "bodyLog",
  "sessionLog": "sessionLog",
}

export type Options = { label: string, id: string | number }[]

export  const skillLevelOptions : Options = [{label: 'beginner', id: 'BEGINNER'}, {label: 'intermediate', id: 'INTERMEDIATE'}, {label: 'advanced', id: 'ADVANCED'}, {label: 'expert', id: 'EXPERT'}, {label: 'any', id: 'ANY'}]
