import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import ImageFieldWithSearch from "./ImageFieldWithSearch";

const MUTATION_PROGRAM = gql`
  mutation Program($input: NewProgramInput!) {
  addProgram(input: $input) {
    id
    title
  }
}
`

const QUERY_SESSIONS = gql`
  query Sessions($input: SessionInput) {
    sessions(input: $input) {
      id
      title
      private
    }
  }
`

type SessionOptions = {label: string, id: number}[]

const CreateProgram = ({ })=> {
  const [sessionOptions, setSessionOptions] = useState<SessionOptions>([])
  const { data : mySessionData, loading : mySessionLoading, error: mySessionError } = useQuery(QUERY_SESSIONS, {
    variables: { "input": { creatorId : getCurrentUser().id } }
  })
  const { data : publicSessionData, loading : publicSessionLoading, error: publicSessionError } = useQuery(QUERY_SESSIONS, {
    variables: { "input": { private : false } }
  })

  const [addProgram, {data: programData, loading: programLoading, error: programError }] = useMutation(MUTATION_PROGRAM)
  
  const navigate = useNavigate()
  
  const { register, setValue, handleSubmit, getValues, control } = useForm({
    defaultValues: {
     title: '',
     description: '',
     image: '',
     private: false,
     sessions: [], //get ids
    }
  });
  
  useEffect(()=>{
    const sessions : {title: string, id: number}[] | []  = mySessionData?.sessions && publicSessionData?.sessions 
    ? [...mySessionData?.sessions, ...publicSessionData?.sessions]
    : []

    const dedupSessions = Array.from(new Set(sessions.map(a => a.id)))
    .map(id => {
      return sessions.find(a => a.id === id)
    })
    
    const sessionOptions: SessionOptions = []
    dedupSessions.forEach((item : any) => {
      sessionOptions.push({
        label: item.title,
        id: item.id
      })
    })

    setSessionOptions(sessionOptions)

    console.log('sessions 2', sessions)
    console.log('sessionOptions', sessionOptions)
  }, [mySessionData, publicSessionData])


  // if(loading) ( <p>Loading</p> )

  // if (error) ( <p>error</p> )

  return (
    <div style={{background: 'whitesmoke', height: '100vh'}}>

      <Box sx={{ margin: '24px', backgroundColor: 'white', padding: '12px', borderRadius: '12px'}}>
        <Box sx={{display: 'flex'}}>
          <Typography  sx={{margin: '12px 0'}} variant="h5" component="div">
            New Program
          </Typography>
          <Box sx={{display: "flex", flex: 1, justifyContent: 'flex-end', alignItems: 'center',  paddingRight: '12px'}}>
          </Box>
        </Box>

        <Divider/>

        <Box 
          sx={{display:'flex', gap: '12px', marginTop: '12px', flexDirection: 'column'}}
          component="form"
          onSubmit={handleSubmit((data => {
            addProgram({
              variables: {
                "input": {
                  ...data,
                  sessions: data.sessions.map((item: any)=>item.id)
                }
              }
            })
            .then(({data})=>{
              console.log('data prog - ', data)
              navigate(`/p/program/${data.addProgram.id}`)
            })
            .catch((e)=>{
              console.log(e)
            })
          }))}
          >
          <TextField
            required
            id="title"
            label="Title"
            placeholder="title" 
            {...register("title", {required: true})}
          />
          <TextField
            required
            id="description"
            label="Description"
            placeholder="description" 
            {...register("description", {required: true})}
          />

          <ImageFieldWithSearch imageUrl={getValues('image')} onChange={(val)=> setValue("image", val)} initialSearchTerm={getValues("title")}/>
                    
          {/* sessions */}
          <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
            Sessions
          </Typography>
          <Controller
            control={control}
            name="sessions"
            rules={{ required: 'true' }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                multiple
                disablePortal
                id="sessions"
                options={sessionOptions}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select sessions" />}
                onChange={(d, val)=>{ 
                  console.log('change', d, val)
                  // @ts-ignore
                  setValue('sessions', val)
                }}
              />
            )}
          />

          <FormControlLabel control={
            <CheckBox
              value={getValues('private')}
              id="private"
              onChange={(e)=>{ setValue('private', e.target.checked) }}
            />} 
            label="Private"
          />

          <Box>
            <Button variant="contained" type="submit">Save</Button>
            <Button variant="contained" color="error">Cancel</Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CreateProgram