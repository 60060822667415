
import React, { createContext } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '../atoms/Alert'

export const ToastContext = createContext({
  toast: {open: false, message: '', severity: 'success'}, 
  setToast: (a: any) => {}
}); 

export default function Layout({children, toast, setToast}: any ) {
  // todo: fix context - it doesn't like the {children} thing
  // const [toast, setToast] = React.useState<{open: boolean, message: string, severity: 'error' | 'success'}>({
  //   open: true, 
  //   message: 'test', 
  //   severity: 'success'})

  console.log('toast', toast)

  return (
    <ToastContext.Provider value={{
      toast,
      setToast
    }}>
      {toast ? <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={()=>{}}>
          <Alert 
            severity={toast.severity as any}
            onClose={()=>{ 
              setToast({...toast, open: false}) 
            }} 
            sx={{ width: '100%' }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Stack> : ''}
      {children}
    </ToastContext.Provider>
  )
}