import Box from '@mui/material/Box';

interface SkeletonProps {
  height?: string
  width?: string
  backgroundColor?: string
  marginBottom?: string
}

const Skeleton = ({height = '20px', width = '50px', backgroundColor = 'lightgrey', marginBottom = '10px'} : SkeletonProps)=> {
  return <Box sx={{height, width, backgroundColor, marginBottom,}} />
}

export default Skeleton