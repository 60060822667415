import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import AddLogForm from "../organisms/AddLogForm";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const QUERY_LOG = gql`
  query Log($input: LogInput!) {
    log(input: $input) {
      id
      createdAt
      mood
      entry
    }
  }
`

const Log = ({ })=> {
  const { id } = useParams()
  const { data, loading, error } = useQuery(QUERY_LOG, {
    variables: { "input": { id } }
  })
  const [dialogOpen, setDialogOpen] = useState(false)

  React.useEffect(() => {
    document.title = 'Serious Trainer | Log';
  }, []);

  if(loading) ( <p>Loading</p> )

  if (error) ( <p>error</p> )

  const log = data ? data.log : null

  const navigate = useNavigate()

  return (
    <div>
      <ArrowBackIcon sx={{cursor:"pointer"}} onClick={()=>{ navigate(-1) }} />
      {log ? <>
        <h1 className=" text-gray-600 mb-4">Session log - {new Date(+log.createdAt).toDateString()}</h1>
        <p>{log.mood}</p>       
        <p>{log.entry}</p>

        <button onClick={()=> { setDialogOpen(true)}}>Edit</button>    

        <AddLogForm open={dialogOpen} setDialogOpen={setDialogOpen} initialValues={{
          id: log.id,
          mood: log.mood,
          activity: log.activity,
          entry: log.entry
        }}/>
      </>
      : <p>loading</p>}
      
    </div>
  )
}

export default Log