import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useForm } from "react-hook-form";
import gql from "graphql-tag"
import { useMutation } from "@apollo/client";
import BodyParts from "../molecules/BodyParts";
import {BODY_PARTS} from "../../utils/fixtures";
import {MUTATION_ACTIVITY_LOG} from "../../utils/queries";
import {ACTIVITY} from "../../utils/fixtures";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

// const MUTATION_ADD_LOG = gql`
// mutation AddLog($input: NewLogInput!) {
//   addLog(input: $input) {
//     id
//     mood
//     entry
//     createdAt
//   }
// }
// `

const MUTATION_ADD_BODY_LOG = gql`
mutation AddBodyLog($input: NewBodyLogInput!) {
  addBodyLog(input: $input) {
    id
    modifiedAt
    sessionLogId
  }
}
`

const MUTATION_EDIT_LOG = gql`
  mutation EditLog($input: EditLogInput!) {
    editLog(input: $input)
  }
`

interface AddBodyLogFormProps {
  open: boolean,
  setDialogOpen: (a: any) => void,
  sessionLogId?: string,
  btnText?: string,
  onClick: () => void,
  // initialValues?: Partial<{
  //   id?: string,
  //   mood?: string,
  //   entry?: string
  //   activity?: string,
  // }>
}

const FREQUENCY_PAIN = {
  1 : 'STRAINED',
  2 : 'SORE',
  3 : 'INJURED',
}
const AddBodyLogForm = ({ open, setDialogOpen, sessionLogId = "12345", btnText = "Save", onClick = () => {} } : AddBodyLogFormProps) => {
  const [bodyPartsValue, setBodyPartsValue] = React.useState([
    // { name: 'chest', muscles: ['chest'], frequency: 1 },
    // { name: 'head', muscles: ['head'], frequency: 3},
    // { name: 'abs', muscles: ['abs'], frequency: 2 },
  ])

  const [addBodyLog, {data, loading, error}] = useMutation(MUTATION_ADD_BODY_LOG)
  // const [editLog, {data: _data, loading: _loading, error: _error}] = useMutation(MUTATION_EDIT_LOG)
  const [addActivityLog] = useMutation(MUTATION_ACTIVITY_LOG)
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      entry: "",
      sessionLogId,
      bodyPartData: []
    }
  });

  const onSubmit = (data : any) => {
    // transform bodyPartsValue
    const formattedValue = bodyPartsValue.map(item => (
      {
        //@ts-ignore
        "bodyPart": BODY_PARTS[item.name],
        //@ts-ignore
        "pain": FREQUENCY_PAIN[item.frequency]
      }
      ))

      console.log('send data over', formattedValue, bodyPartsValue)

    // if (editMode) {
    //   editLog({
    //     variables: {
    //       "input": {...data, id: initialValues.id}
    //     }
    //   })
    // }
    // else {
      addBodyLog({
        variables: {
          "input": {
            "bodyPartData": formattedValue,
            "sessionLogId": sessionLogId ?? "",
            "entry": data.entry
          }
        }
      })

      const bodyPart: string[] = []
      formattedValue.forEach(item=> {bodyPart.push(item.bodyPart)})

      addActivityLog({
        variables: {
          "input": {
            "activity": ACTIVITY.bodyLog,
            "activityID": sessionLogId ?? "",
            "title": "Body log",
            "description": `${bodyPart.length ? `Body parts: ${bodyPart.join(', ')}` : `...`}. Comments: ${data.entry}`,
            "metaData": "",
          }
        }
      })
      .catch((e)=>{
        console.log(e)
      })
    // }

    setDialogOpen(false)

    onClick()
  };

  return (
    <div>
       <Modal
        open={open}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
          sx={style} 
          className="rounded"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          // onSubmit={()=>{}}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" className="pb-6">
              {"Job done! How is your body..."}
            </Typography>

            <BodyParts initialValue={bodyPartsValue} getBodyData={setBodyPartsValue}/>
  
            {/* description */}
            <TextareaAutosize
              aria-label="entry"
              minRows={3}
              placeholder="Anything thing else to add..."
              className="w-full mb-6"
              autoFocus={true}
              {...register("entry")}
            />
            
            <div className="flex">
              <div className="flex flex-1 justify-end">
                <Button 
                  variant="contained"
                  type="submit"
                >
                  <span className="lowercase">{btnText}</span>
                </Button>
              </div>
            </div>
        </Box>
      </Modal>
    </div>
  )
}

export default AddBodyLogForm