import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useForm, Controller } from "react-hook-form";
import gql from "graphql-tag"
import { useMutation } from "@apollo/client";
import {MUTATION_ACTIVITY_LOG} from "../../utils/queries";
import { ACTIVITY } from "../../utils/fixtures";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const MUTATION_ADD_LOG = gql`
mutation AddLog($input: NewLogInput!) {
  addLog(input: $input) {
    id
    mood
    entry
    createdAt
    activity
  }
}
`

const MUTATION_EDIT_LOG = gql`
  mutation EditLog($input: EditLogInput!) {
    editLog(input: $input)
  }
`

interface AddLogFormProps {
  open: boolean,
  setDialogOpen: (a: any) => void,
  onClick?: () => void,
  initialValues?: Partial<{
    id?: string,
    mood?: string,
    entry?: string
    activity?: string,
  }>
}

const AddLogForm = ({ open, setDialogOpen, onClick = ()=>{}, initialValues = { mood: "GOOD", activity: "GENERAL" } } : AddLogFormProps) => {
  const [addLog, {data, loading, error}] = useMutation(MUTATION_ADD_LOG)
  const [addActivityLog] = useMutation(MUTATION_ACTIVITY_LOG)
  const [editLog, {data: _data, loading: _loading, error: _error}] = useMutation(MUTATION_EDIT_LOG)
  const { register, handleSubmit, control } = useForm({
    defaultValues: initialValues
  });
  const editMode = initialValues?.id

  const onSubmit = (data : any) => {
    if (editMode) {
      editLog({
        variables: {
          "input": {...data, id: initialValues.id}
        }
      })

      addActivityLog({
        variables: {
          "input": {
            "activity": ACTIVITY.sessionLog,
            "activityID": data.id,
            "title": `${data.activity} session log`,
            "description": `mood: ${data.mood}, entry: ${data.entry}`,
            "metaData": "",
          }
        }
      })
    }
    else {
      addLog({
        variables: {
          "input": data
        }
      })
      .then(({data})=>{
        onClick()

        const log = data?.addLog
        addActivityLog({
          variables: {
            "input": {
              "activity": ACTIVITY.sessionLog,
              "activityID": log.id,
              "title": `${log.activity} session log`,
              "description": `mood: ${log.mood}, entry: ${log.entry}`,
              "metaData": "",
            }
          }
        })
      })
      .catch((e)=>{
        console.log(e)
      })
    }
    
    setDialogOpen(false)
  };

  return (
    <div>
       <Modal
        open={open}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
          sx={style} 
          className="rounded"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" className="pb-6">
              {editMode ? "Edit your log" : "Log your session"}
            </Typography>

            <div className="flex flex-row pb-6">
              <div className="pr-3">
                {/* activity dropdown */}
                <InputLabel id="activity-select-label">Activity</InputLabel>
                <Controller
                  control={control}
                  name="activity"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="activity-select-label"
                      id="activity-select"
                      value={value ?? "GENERAL"}
                      label="activity"
                      onChange={onChange}
                    >
                      <MenuItem value="GENERAL">General</MenuItem>
                      <MenuItem value={"BASKETBALL"}>Basketball</MenuItem>
                      <MenuItem value={"GYM"}>Gym</MenuItem>
                      <MenuItem value={"BIKE"}>Bike</MenuItem>
                      <MenuItem value={"HOME_WORKOUT"}>Home workout</MenuItem>
                    </Select>
                  )}
                />
              </div>
    
              <div>
                {/* mood */}
                <InputLabel id="mood-select-label">Mood</InputLabel>
                <Controller
                  control={control}
                  name="mood"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="mood-select-label"
                      id="mood-select"
                      value={value}
                      label="mood"
                      onChange={onChange}
                    >
                      <MenuItem value="DISASTROUS">Disastrous</MenuItem>
                      <MenuItem value="BAD">Bad</MenuItem>
                      <MenuItem value="OK">Ok</MenuItem>
                      <MenuItem value="GOOD">Good</MenuItem>
                      <MenuItem value="FANTASTIC">Fantastic</MenuItem>
                    </Select>
                  )}
                />
              </div>
            </div>
  
            {/* description */}
            <TextareaAutosize
              aria-label="entry"
              minRows={3}
              placeholder="What would you like to log?"
              className=" w-full mb-6"
              autoFocus={true}
              {...register("entry")}
            />
            
            <div className="flex">
              {/* add a body log */}
              {/* <Button variant="outlined" disabled>
                <span className="lowercase">Add body log</span>
              </Button> */}
              <div className="flex flex-1 justify-end">
                <Button 
                  variant="contained"
                  type="submit"
                >
                  <span className="lowercase">Save</span>
                </Button>
              </div>
            </div>
        </Box>
      </Modal>
    </div>
  )
}

export default AddLogForm