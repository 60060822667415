import React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import GoogleIcon from '@mui/icons-material/Google';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useNavigate, useParams } from "react-router-dom";
import { saveCurrentUser } from '../../services'
import logoSrc from '../../img/logo.png'
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Loader from '../atoms/Loader'

export const LOGIN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      token
      user {
        id
        firstName
        lastName
        verified
        role
        avatar
      }
    }
  }
`

const Login = ()=> {
  const { email } = useParams()
  const [login, {data, loading, error}] = useMutation(LOGIN, { errorPolicy: 'all' })
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: email ?? ""
    }
  });

  React.useEffect(() => {
    document.title = 'Serious Trainer | Login';
  }, []);

  const debug = window?.location?.hash === '#debug'

  const provider = new GoogleAuthProvider();

    const auth = getAuth();

    function stateChangeCb({user}) {

      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        
        login({
          variables: {
            "input": {
              "email": user.email,
              "password": user.uid, //todo: encrypt this
              "accessToken": user.accessToken ?? null,
            }
          }
        })
        .then(({ data }) => {
          if (data) {
            const path = (data.signIn.user.firstName + '-' + data.signIn.user.lastName).toLowerCase()
        
            saveCurrentUser(data.signIn);
            navigate(`/p/${path}`) 
          }
        })
        .catch(e => {
          console.log('google login', e.message)
        })
      } else {
        console.log('observer - signed out')
        // User is signed out
        // 
      }
    }
    

  const navigate = useNavigate()

  const onSubmit = data => {
    login({
      variables: {
        "input": data
      }
    })
    .then(({ data }) => {
      if (data) {
        const path = (data.signIn.user.firstName + '-' + data.signIn.user.lastName).toLowerCase()
    
        saveCurrentUser(data.signIn);
        navigate(`/p/${path}`) 
      }
    })
    .catch(e => {
      console.log('e login', e.message)
    })
  };

  if(loading) {
    return <Loader/>
  }

  return <div style={{    
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    }}>
  <img alt="logo" src={logoSrc} style={{width: "200px", 'marginBottom': '30px'}} />

    {error && (
      <div style={{background: "lightcoral", padding: 5, margin: 10, borderRadius: 5}}>{error.graphQLErrors.map(({ message }, i) => {
        
        const errorMsg = message.includes("Cannot return null for non-nullable") ? "Looks like you haven't signed up yet. Please sign up first." : message

        return (
        <p key={i}>{errorMsg}</p>
      )})}
      </div>
    )}

  <Box
    component="form"
    style={{ display: 'flex', flexDirection: 'column' }}
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
    }}
    autoComplete="off"
    onSubmit={handleSubmit(onSubmit)}
  > 
        <>
          <TextField
          required
          id="outlined-required"
          label="Email"
          type="email"
          placeholder="email" {...register("email", {required: true})}
        />
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          placeholder="password" {...register("password", {required: true})}
        />
        {debug && <TextField
          id="outlined-password-input"
          label="Access token"
          type="password"
          placeholder="accessToken" {...register("accessToken", {required: false})}
        />}
        <Button variant="contained" type="submit" endIcon={loading ? <p>⏱</p> : null}>Login</Button>
      </>

      <Box sx={{display: 'flex', padding: '30px 0px', alignItems: 'center'}}>
        <Box sx={{width: '110px', marginRight: '10px', height: '2px', background: 'lightgrey'}}/>
        <Box sx={{margin: [0, '15px']}}>or</Box>
        <Box sx={{width: '110px', marginLeft: '10px', height: '2px', background: 'lightgrey'}}/>
      </Box>

    <Button
      onClick={() => {
        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then(stateChangeCb)
      }}
      variant="outlined"
    >
      <GoogleIcon /> {" "}
      <span style={{paddingLeft: 10}} >Continue with Google</span>
    </Button>
    <br/>
    <p>Don't have an account? <span style={{ textDecoration: 'underline', color: 'green', cursor: 'pointer'}} onClick={()=>{ navigate('/signup')}}>Sign up</span></p>

  </Box>
</div>
}

export default Login