import React, { useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import SearchImage from "./SearchImage";
import noImageSrc from '../../img/no-image.png'

interface ImageFieldWithSearchProps {
  onChange: (url: string)=>void
  imageUrl: string
  initialSearchTerm?: string
}

const ImageFieldWithSearch = ({onChange, imageUrl, initialSearchTerm} : ImageFieldWithSearchProps)=> {
  const [showImageSearch, setShowImageSearch] = useState(false)
  const [imgVal, setImgVal] = useState(imageUrl)
  const [selectedImg, setSelectedImg] = useState(imageUrl)

  return (
      <Box>
        {!showImageSearch ? <Box sx={{display:'flex'}}>
          <img 
            style={{height: '56px'}} 
            src={selectedImg ? selectedImg : noImageSrc} 
            alt="image thumbnail" 
            onError={(e)=>{
              console.log('e', e)
              console.log('selectedImg', selectedImg)
              console.log('noImageSrc', noImageSrc)

              setSelectedImg("")
            }}
            />
          <TextField
            id="image"
            label="Image"
            placeholder="image url" 
            value={imgVal}
            onBlur={(e)=>{ 
              console.log('blur', e.target.value); 
              setSelectedImg(e.target.value) 

            }}
            onChange={(e)=>{
              console.log('change', e.target.value); 
              setImgVal(e.target.value)
              onChange(e.target.value) 
            }}
            sx={{flex: 1}}
            /> 
          <Button variant="outlined" onClick={()=> setShowImageSearch(true)}><SearchIcon/></Button>
        </Box> :
        <SearchImage 
          onChange={(url, alt)=>{
            console.log('search image', url, alt)
            setSelectedImg(url)
            setImgVal(url)
            setShowImageSearch(false)

            onChange(url) 
          }}
          onClose={()=>{ setShowImageSearch(false) }}
          initialSearchTerm={initialSearchTerm}
        />}
      </Box>
      
  )
}

export default ImageFieldWithSearch