// use fetch to make a call to this endpoint: https://api.unsplash.com/photos
// set application’s access key via the HTTP Authorization header:
// use your access key to pass in as the client_id parameter
// export the response from this function

interface fetchImagesProps {
  query?: string 
}

export const fetchImages = async ({ query } : fetchImagesProps) => {
  const params = new URLSearchParams({
    ...(query && {query}),
  }).toString();

  const response = await fetch(
    `https://api.unsplash.com/search/photos?${params}&per_page=20`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
    }
  });
  const data = await response.json();
  return data;
}

interface queryExercisesProps {
  name?: string 
  type?: string 
  muscle?: string 
  difficulty?: string 
  offset?: number 
}

export const queryExercises = async ({ name, type, muscle, difficulty, offset}: queryExercisesProps | any ) => {

  const params = new URLSearchParams({
    ...(name && {name}),
    ...(type && {type}),
    ...(muscle && {muscle}),
    ...(difficulty && {difficulty}),
    ...(offset && {offset}),
  }).toString();

  const response = await fetch(
    `https://api.api-ninjas.com/v1/exercises?${params}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': process.env.REACT_APP_API_NINJAS_KEY || ''
    }
  });
  const data = await response.json();
  return data;
}

export default {
  fetchImages,
  queryExercises
} 