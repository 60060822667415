import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from "@mui/material";
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import ProgramsSection from "../organisms/ProgramsSection";
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CommentIcon from '@mui/icons-material/Comment';

const activity = [
  // create 5 unique objects
  // use faker to generate random images
  // use faker to generate random names
  // use faker to generate random activities
  // dt_entry should be unique
  {activity: "Completed phase 1 of Knee program", dt_entry: '6/12/2023' },
  {activity: "Added a body log: glutes are sore", dt_entry: '10/05/2023' },
  {ai: true, activity: "[AI physio] - Based on your current activity today, you should stretch your quads and glutes.", dt_entry: '19/05/2023' },
  {ai: true, activity: "[AI physio] - Because of your logs and program, you should book a session with your physio; how about next Friday?.", dt_entry: '19/05/2023' },
  
]

const bookings = [
  // create 5 unique objects
  // use faker to generate random images
  // use faker to generate random names
  // use faker to generate random activities
  // dt_entry should be unique
  {name: faker.name.findName(), reason: 'tight lower back', dt_entry: '6/12/2023' },
  {name: faker.name.findName(), reason: 'knee pain', dt_entry: '10/05/2023' },
  {name: faker.name.findName(), reason: 'general physio', dt_entry: '19/05/2023' },
  
]

const athlete = {
  name: faker.name.findName(),
}

const AthleteView = ()=> {
  React.useEffect(() => {
    document.title = 'Serious Trainer | Athlete View';
  }, []);

  const navigate = useNavigate()

  return(
    <Box sx={{background: 'whitesmoke', height:'100%'}}>
      <Box sx={{ padding: '12px 24px 24px', display: 'flex', flex: 1,
      justifyContent: 'space-between' }}>
        <ArrowBackIcon sx={{cursor:"pointer"}} onClick={()=>{ navigate(-1) }} />
      </Box>

      <Box>
        <Box sx={{display: 'flex', backgroundSize: 'cover', backgroundColor:'black', flexDirection: 'column', justifyContent: 'end', padding: '12px 24px'}}>
          <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
            Athlete: {athlete.name}
          </Typography>
        </Box>

        {/* activity */}
        <Box sx={{margin: '24px'}}>
          <Typography sx={{margin: '0 0 12px'}} variant="h5" component="div">
            Activity
          </Typography>

          {activity.map((client, index) => {
            return <Paper elevation={3} key={index} sx={{display:'flex', flexFlow: 'wrap', justifyContent: 'space-around', padding: '0 24px', alignItems: 'center', cursor: 'pointer', backgroundColor:"#70809021"}}>
            {/* activity */}
            <Typography variant="body1" component="div" sx={{margin: ' 24px', flex: 1}}> 
              <Box sx={{display:"flex", gap: '6px'}}>{client.ai && <SmartToyIcon/>} {client.activity}</Box>
            </Typography>
            {/* ACTIVE STATUS */}
            <Typography variant="body2" component="div" sx={{margin: ' 24px'}}> 
              {client.dt_entry}
            </Typography>
            {/* amend booking */}
            <Box sx={{display:"flex"}}>
              <Button variant="text" sx={{height: ' 24px'}}>
                <FavoriteIcon/>
              </Button>
              <Button variant="text" sx={{height: ' 24px'}}>
                <CommentIcon/>
              </Button>
            </Box>
          </Paper>
          })}
        </Box>

        {/* appointments */}
        <Box sx={{margin: '24px'}}>
          <Typography sx={{margin: '0 0 12px'}} variant="h5" component="div">
            Appointments
          </Typography>

          {bookings.map((client, index) => {
            return <Paper elevation={3} key={index} sx={{display:'flex', flexFlow: 'wrap', justifyContent: 'space-around', padding: '0 24px', alignItems: 'center', cursor: 'pointer'}}>
            {/* activity */}
            <Typography variant="body1" component="div" sx={{margin: ' 24px', flex: 1}}> 
              {client.reason}
            </Typography>
            {/* ACTIVE STATUS */}
            <Typography variant="body2" component="div" sx={{margin: ' 24px'}}> 
              {client.dt_entry}
            </Typography>
            {/* amend booking */}
            <Button variant="outlined" sx={{height: ' 24px'}}>
              Amend
            </Button>
          </Paper>
          })}
        </Box>

        {/* My plans and their stats */}
        <Box sx={{margin: '24px'}}>
          <ProgramsSection title="Active program" />
        </Box>
      </Box>
    </Box>
  )
}

export default AthleteView