import React from "react";
import Box from '@mui/material/Box';
import SessionWorkout from "../organisms/SessionWorkout";
import Typography from '@mui/material/Typography';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Layout from "../organisms/Layout";

const SearchSessions = ()=> {
  React.useEffect(() => {
    document.title = 'Serious Trainer | Search Sessions';
  }, []);
  
  return(
    <Layout>
      <Box sx={{background: 'whitesmoke', height:'100%', marginBottom: '50px'}}>
        <Box sx={{display: 'flex', backgroundSize: 'cover', backgroundColor:'black', flexDirection: 'column', justifyContent: 'end', padding: '12px 24px'}}>
            <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
            Select a session
          </Typography>
        </Box>  
  
        <Box sx={{padding: '24px'}}><SessionWorkout /></Box>  
      </Box>
    </Layout>
  )
}

export default SearchSessions