import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { getAuth, signOut } from "firebase/auth";
import { logout } from '../../services'
import { getCurrentUser } from '../../services'

const TopNav = () => {
  const navigate = useNavigate()
  const isUserLoggedIn = !!getCurrentUser()

  return <Box sx={{ padding: '12px 24px 24px', display: 'flex', flex: 1,
      justifyContent: 'space-between', backgroundColor: '#f3f5f9' }}>
        <ArrowBackIcon sx={{cursor:"pointer"}} onClick={()=>{ navigate(-1) }} />
        <Button
          onClick={()=>{ 
            if(!isUserLoggedIn) {
              navigate('/login')
              return
            } 

            const auth = getAuth();
            signOut(auth).then(() => {
              // Sign-out successful.
              logout()
              navigate('/')
            }).catch((error) => {
              // An error happened.
              console.log('error signing out', error)
            });
            
            }}
        >{!isUserLoggedIn ? 'Sign in' : 'Log out'}</Button>
     </Box>
}

export default TopNav