import React from "react";
import { useMutation } from "@apollo/client";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "@mui/material";
import imgPlaceholder from '../../img/exercise-placeholder.png'
import {MUTATION_EXERCISE} from '../organisms/CreateExercise'
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '../atoms/Alert'
import CreateExercise from "../organisms/CreateExercise";
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';

interface ExerciseCardProps {
  title: string
  onClick: ((id: string, label: string ) => void )
  id?: string
  description?: string
  type?: string
  difficulty?: string
  image?: string
  video?: string
  equipment?: string
  targetMuscles?: string[],
  newExercise?: boolean
}
const ExerciseCard = ({id, title, description, type, difficulty, image, video, equipment, newExercise, targetMuscles, onClick = (id, label)=>{}} : ExerciseCardProps) => {
  const [addExercise, {data: sessionData, loading: sessionLoading, error: sessionError }] = useMutation(MUTATION_EXERCISE)
  const [toast, setToast] = React.useState<{open: boolean, message: string, severity: 'error' | 'success'}>({open: false, message: '', severity: 'success'})
  const [showEditForm, setShowEditForm] = React.useState(false)
  const [showVideoPreview, setShowVideoPreview] = React.useState(false)

  const values = {
    title,
    description,
    targetMuscles,
    equipment,
    type,
    "private": false,
    "skillLevel": difficulty ?? 'ANY',
    "image": '',
    "video": '',
  } as any

  const background = newExercise ? "#e0e0ff" : "aliceblue" 

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: '10px', background: background, width: '100%', padding: '10px', borderBottom: '1px solid lightgrey', boxShadow: '4px 4px 10px 0px #dae2e2', minWidth: '200px'}}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={()=>{}}>
          <Alert onClose={()=>{ setToast({...toast, open: false})}} sx={{ width: '100%' }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Stack>

      <Box sx={{display: 'flex', justifycontent: 'center', gap: '10px', alignItems: 'end'}}>
        <Typography className="line-clamp-2" variant="h2" fontWeight={700} fontSize={14} >
          {title}
        </Typography>
        <Box>
          {type && <ExercisePill text={type}/>}
          <ExercisePill text={difficulty} kind="difficulty"/>
        </Box>
      </Box>
      {/* img and main detail */}
      <Box sx={{display: 'flex', gap: '10px'}}>
        <Box>
          <img style={{borderRadius: '10px', maxWidth: '80px'}} width={'80px'} alt="exercise image" src={image ?? imgPlaceholder}/> 
        </Box>
        <Box>
          <Typography className="line-clamp-2" variant="h2" fontWeight={500} fontSize={12} color={"slategrey"}>
            {description}
          </Typography>
        </Box>
      </Box>
      {/* video preview */}
      <Box>
        {showVideoPreview ? <iframe width="500px" height="300px" src={video} style={{maxWidth: "560px"}} title="Serious trainer player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/> : null}
      </Box>
      {/* bottom panel */}
      <Box sx={{display: 'flex', gap: '10px', justifyContent: 'flex-end', alignItems: 'center'}}>
        {/* col 1 */}
        {newExercise && <Button variant="outlined" onClick={()=>{
          setShowEditForm(!showEditForm)
        }} >
         <EditIcon/>
        </Button>}

        {video && <Button variant="outlined" onClick={()=>{setShowVideoPreview(!showVideoPreview)}} >
          <VideoCameraBackIcon/>
        </Button>}

        <Button variant="outlined"
          onClick={()=>{
            if(!newExercise && id) {
              // don't create new, return ID and title
              onClick(id, title)
            } 
            else {
              addExercise({
                variables: {
                  "input": {
                    title,
                    description,
                    type,
                    equipment,
                    "skillLevel": difficulty,
                    "targetMuscles": targetMuscles ?? [null],
                    "private": false,
                    "image": '',
                    "video": '',
                  }
                }
              })
              .then(({data})=>{
                console.log('success heres your exercise - ', data)
                setToast({open: true, message: 'Successfully selected exercise', severity:"success"})

                // return ID
                onClick(data?.addExercise?.id, data?.addExercise?.title)
              })
              .catch((e)=>{
                console.log(e)
                setToast({open: true, message: 'Error selecting exercise, try again', severity:"error"})
              })
            }
          }}
        >
          select
        </Button>
      </Box>

      {showEditForm && 
      <CreateExercise 
        defaultValue={values} 
        targetMusclesMultiSelect={false} 
        onClose={()=>{ setShowEditForm(false) }}
        onSubmit={(id, label)=>{
          onClick(id, label)

          setShowEditForm(false)
        }}/>}
    </Box>
  )
}

export default ExerciseCard


const ExercisePill = ({text, kind = 'type'} : {text: string | undefined, kind?: 'type' | 'difficulty'})=> {

  return <span style={{backgroundColor: kind == "type" ? 'deepskyblue' : 'gray', color: 'white', marginRight: '4px', padding: '0 3px 2px', borderRadius: '5px', fontSize: '10px'}}>{text}</span>
}