import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Layout from "../organisms/Layout";

const MUTATE_MY_DATA = gql`
  mutation EditMyData($input: EditUserInput!) {
    editUser(input: $input) 
}
`

const QUERY_MY_DATA = gql`
  query getMyData {
    me {
      id
      firstName
      lastName
      email
      avatar
      activities
      role
    }
}
`

const EditProfile = ({ })=> {
  const {data, loading, error } = useQuery(QUERY_MY_DATA)
  const [editLog, {data: _data, loading: _loading, error: _error}] = useMutation(MUTATE_MY_DATA)
  const { register, setValue, handleSubmit, getValues, control } = useForm({
    defaultValues: {
      // rather than calling getCurrentUser() might be better to query the db
      id: getCurrentUser().id,
      firstName: data?.me?.firstName,
      lastName: data?.me?.lastName,
      avatar: data?.me?.avatar,
      activities: data?.me?.activities,
    }
  });

  React.useEffect(() => {
    document.title = 'Serious Trainer | Profile Edit';
  }, []);

  console.log('data',data?.me)

  const navigate = useNavigate()

  if(loading) {
    return <p>Loading</p>
  }

  if (error) {
    return <p>error</p>
  }


  return (
    <Layout>
      <div style={{background: 'whitesmoke', height: '100vh'}}>
  
        <Box sx={{display: 'flex', backgroundSize: 'cover', backgroundColor:'black', flexDirection: 'column', justifyContent: 'end', padding: '12px 24px'}}>
            <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
              Edit Profile
            </Typography>
        </Box>
  
        <Divider/>
  
        <Box 
            className="rounded"
            component="form"
            onSubmit={handleSubmit((data => {
              editLog({
                variables: {
                  "input": {
                    ...data,
                  }
                }
              })
              .then(()=>{
                // todo: add later
                // setToast({open: true, message: 'Session created successfully', severity:"success"})
                console.log('yaay you updated your profile')
              })
              .catch((e)=>{
                console.log(e)
              })
            }))}
            sx={{display: 'flex', flexDirection: 'column', padding: '24px', gap: '12px'}}
          >
            <TextField
              id="firstName"
              label="first Name"
              placeholder="first name" 
              defaultValue={data?.me?.firstName}
              {...register("firstName")}
            />    
  
            <TextField
              id="lastName"
              label="last Name"
              placeholder="last name" 
              defaultValue={data?.me?.lastName}
              {...register("lastName")}
            />    
  
            <TextField
              id="avatar"
              label="avatar"
              placeholder="avatar" 
              defaultValue={data?.me?.avatar}
              {...register("avatar")}
            />    
  
            <TextField
              id="activities"
              label="activities"
              placeholder="activities" 
              defaultValue={data?.me?.activities}
              {...register("activities")}
            />    
             
            <Box>
              <Button variant="contained" type="submit">Save</Button>
              <Button variant="contained" color="error">Cancel</Button>
            </Box>
        </Box>
        
      </div>
    </Layout>
  )
}

export default EditProfile