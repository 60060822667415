import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Select, MenuItem } from "@mui/material";
import { BODY_PARTS, Options, skillLevelOptions  } from "../../utils/fixtures";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '../atoms/Alert'

import { fetchImages } from '../../api'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';


interface SearchImageProps {
  onChange: (url: string, alt?: string) => void
  onClose: () => void
  initialSearchTerm?: string
}

const SearchImage = ({onChange, onClose, initialSearchTerm} : SearchImageProps )=> {
  const [search, setSearch] = React.useState(initialSearchTerm || '')
  const [images, setImages] = React.useState([])
  const [selectedImage, setSelectedImage] = React.useState({url: '', openDialog: false})
  const [loading, setLoading] = React.useState(false)

  return (
  <Box 
    sx={{
      background: 'aliceblue',
      borderRadius: '5px',
      padding: '20px'
    }}
  >
    <Box 
      component="form"
      sx={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <TextField
        id="search-image"
        label="image search"
        placeholder="bicep curls, etc." 
        defaultValue={search}
        onChange={(e)=>{ setSearch(e.target.value) }}
      />
      <Button 
        variant="contained" 
        type="submit" 
        onClick={(e)=> {
          e.preventDefault()
          console.log('search', search)

          setLoading(true)

          fetchImages({query: search})
          .then((data : { results: [], total: number, total_pages: number})=>{
            console.log('fetchImages', data)

            setImages(data.results)
            setLoading(false)
          })
        }}>Search</Button>
      <Button 
        variant="outlined" 
        type="button" 
        onClick={()=> {
          onClose()
        }}><CloseIcon/></Button>
    </Box>

    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))', gap: '10px', cursor: 'pointer'
    }}>

      {loading && <Typography variant="h6">Loading...</Typography>}
      
      {images?.length > 0 && images?.map(image => {
        const { alt_description, urls, id }  = image
  
        return (
          <Box key={id} >
            <Box
              sx={{
                width: 'auto',
                height: '130px',
                overflowY: 'hidden'
              }}
            >
              <AspectRatioIcon 
                sx={{
                  position: 'relative',
                  right: '-80px',
                  top: '65px',
                  color: 'white',
                  fontSize: '16px'
                }}
              />
              {/* @ts-ignore */}
              <img src={urls.thumb} alt={alt_description}
                // @ts-ignore
                onClick={()=>{ setSelectedImage({url: urls.regular, openDialog: true}) }}   
                />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button 
                variant="outlined" 
                sx={{width: '-webkit-fill-available'}}
                onClick={()=>{ 
                  {/* @ts-ignore */}
                  onChange(urls.regular, alt_description) 
                }} 
              > select </Button>
            </Box>
          </Box>
        )
      })}
    </Box>

    <Modal
        open={selectedImage.openDialog}
        onClose={() => setSelectedImage({...selectedImage, openDialog: false})}
        aria-labelledby="modal-image"
        onClick={()=>{ setSelectedImage({...selectedImage, openDialog: false}) }}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px'
          }}
        >
          <img src={selectedImage.url} style={{ width: 'auto', height: '90vh'}}/>
        </Box>
    </Modal>
  </Box>
  )
}

export default SearchImage