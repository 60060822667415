import axios, { AxiosPromise } from 'axios';

// const API_URL = "http://localhost:4000/"

// export const headers = {
//   'Content-Type': 'application/json',
// };

export const logout = () => {
  localStorage.removeItem("st:user");
};

export const saveCurrentUser = (data) => {
  localStorage.setItem("st:user", JSON.stringify(data))
};

export const getCurrentUser = () => {
  const localStorageUser = localStorage.getItem("st:user")

  if (localStorageUser == "undefined") { return null }

  try {
    const userObj = JSON.parse(localStorageUser)
    return userObj.user
  }
  catch(e) {
    console.log('error', e.message)
  }
};

export const getToken = () => {
  const localStorageUser = localStorage.getItem("st:user")

  if (localStorageUser == "undefined") { return null }

  try {
    const userObj = JSON.parse(localStorageUser)
    return userObj.token 
  }
  catch(e) {
    console.log('error', e.message)
  }


};

export default {
  logout,
  saveCurrentUser,
  getCurrentUser,
  getToken
};


