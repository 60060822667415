import React, { useEffect } from "react";
import { useStopwatch } from 'react-timer-hook';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import { Button } from "@mui/material";

interface TimerProps {
  onEnd: (n: number) => void,
  pauseTimer: boolean
}

const Timer = ({ onEnd, pauseTimer } : TimerProps)=> {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
  } = useStopwatch({ autoStart: true });

  useEffect(()=>{
    if (pauseTimer) {
      pause()
    }
  },[pauseTimer])

  const getDurationInMins = ()=> {
    return (days * 24 * 60) + (hours * 60) + minutes
  }

  return (
    <div style={{display: 'flex', flexDirection:'row', alignItems:"center", justifyContent: 'space-between', position: 'sticky', top: '0', width: '100%', backgroundColor: 'black', zIndex: '10'}}>
      <div></div>
      <div style={{fontSize: '50px', color:"white"}}>
        <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      <div style={{marginRight: '50px'}}>
        {isRunning ? <Button onClick={pause} color="error">
          <PauseCircleFilledOutlinedIcon style={{width: '50px', height: '50px'}}/>
        </Button> 
          : <Button onClick={start}>
              <PlayCircleFilledOutlinedIcon style={{width: '50px', height: '50px'}}/>
            </Button>
        }

        {!isRunning ? <Button onClick={()=>onEnd(getDurationInMins())}>End workout</Button> : ""}
      </div>
    </div>
  );
}

export default Timer