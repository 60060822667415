
import BottomNav from '../molecules/BottomNav'
import TopNav from '../molecules/TopNav'
import { getCurrentUser } from '../../services'
import React, { createContext } from 'react';

export const AiChatContext = createContext({aiChatOpen: false, aiChatCommand: undefined, setAiChatCommand: (a: any) => {}} );


const Layout = ({children}: {children:React.ReactNode}) => {
  const [aiChatCommand, setAiChatCommand] = React.useState(undefined)

  const isUserLoggedIn = !!getCurrentUser()

  return (
    <AiChatContext.Provider value={{
      aiChatOpen: !!aiChatCommand,
      aiChatCommand,
      setAiChatCommand
      }}>
      <div style={{padding: isUserLoggedIn ? '0 0 50px 0' : 0}}>
        <TopNav/>
        {children}
        <BottomNav/>
      </div>
    </AiChatContext.Provider>
  )
}

export default Layout