import React from "react";
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import BodyPartsForm from "../organisms/AddBodyLogForm";
import AddLogForm from "../organisms/AddLogForm";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LogSection = ({ name })=> {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [bodyLogOpen, setBodyLogOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <div>
      <Box sx={{display: 'flex', justifyContent: 'center', margin: '24px 20%', gap: '12px'}}>
         <Button size="small" variant="contained" onClick={()=>{
            setDialogOpen(true)
          }}>
            Log Session
         </Button>
         <Button size="small" variant="outlined" onClick={()=>{
            setBodyLogOpen(!bodyLogOpen)
          }}>
            Log Body
         </Button>
      </Box>

      <BodyPartsForm
        open={bodyLogOpen}
        setDialogOpen={setBodyLogOpen}
      />

      <AddLogForm open={dialogOpen} setDialogOpen={setDialogOpen}/>
      
    </div>
  )
}

export default LogSection