import React, { useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import SearchVideo from "./SearchVideo";
import noVideoSrc from '../../img/no-video.png'

interface VideoFieldWithSearchProps {
  onChange: (thumbUrl: string)=>void
  videoUrl: string
  initialSearchTerm?: string
}

const VideoFieldWithSearch = ({onChange, videoUrl, initialSearchTerm} : VideoFieldWithSearchProps)=> {
  const [showVideoSearch, setShowVideoSearch] = useState(false)
  const [videoVal, setVideoVal] = useState(videoUrl)

  return (
      <Box>
        {!showVideoSearch ? <Box sx={{display:'flex'}}>
          {!videoVal ? <img 
            style={{height: '56px'}} 
            src={noVideoSrc} 
            alt="image thumbnail" 
            /> :
          <iframe width="56px" height="56px" src={videoVal} style={{maxWidth: "560px"}} title="Serious trainer player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
          }

          <TextField
            id="video"
            label="Video"
            placeholder="video url" 
            value={videoVal}
            onBlur={(e)=>{ 
              console.log('blur', e.target.value); 

            }}
            onChange={(e)=>{
              console.log('change', e.target.value); 
              setVideoVal(e.target.value)
              onChange(e.target.value) 
            }}
            sx={{flex: 1}}
            /> 
          <Button variant="outlined" onClick={()=> setShowVideoSearch(true)}><SearchIcon/></Button>
        </Box> :
        <SearchVideo 
          onChange={(url)=>{
            console.log('search video', url)
            setVideoVal(url)
            setShowVideoSearch(false)

            onChange(url) 
          }}
          onClose={()=>{ setShowVideoSearch(false) }}
          initialSearchTerm={initialSearchTerm}
        />}
      </Box>
      
  )
}

export default VideoFieldWithSearch