import React from "react";
import Model, { IExerciseData, IMuscleStats, ModelType } from 'react-body-highlighter';

interface BodyPartsProps {
  getBodyData: (v :any) => void,
  initialValue?: IExerciseData[] | any
}

const BodyParts = ({ getBodyData, initialValue } : BodyPartsProps)=> {
  const [type, setType] = React.useState(ModelType.ANTERIOR)

  // remapping data = name: muscle name; muscles: 1 item in array; frequency: type of pain {0 :none, 1: sore, 2: strained, 3: injured}
  const [bodyData, setBodyData] =  React.useState<IExerciseData[]>(initialValue);

  //@ts-ignore
  const handleView = ()=> {setType(type == ModelType.ANTERIOR ? ModelType.POSTERIOR : ModelType.ANTERIOR)}

  const handleBodyPartsClick = ({ muscle } : IMuscleStats) => {
    // const muscle =it.muscle 
    const foundIndex = bodyData.findIndex((part)=>(part.name === muscle ))

    const tempBodyData = [...bodyData]

    if(foundIndex > -1) {
      // modify item  in bodyData
      // increment on each click
      const item : IExerciseData = tempBodyData[foundIndex]
      if (!!item.frequency && item.frequency < 3) {
        item.frequency++
        tempBodyData[foundIndex] = item
      }
      else {
        tempBodyData.splice(foundIndex, 1)
      }
    }
    else {
      // create item in bodyData
      // default pain to 1
      const item : IExerciseData  = { name: muscle, muscles: [`${muscle}`], frequency: 1 }
      tempBodyData.push(item)
    }

    setBodyData(tempBodyData)
    getBodyData(tempBodyData)
  };

  return <div>
    <button onClick={handleView} type="button">{type === "anterior" ? "View Back" : "View Front"}</button>
   <div style={{display: 'flex' }}>
      <Model
        data={bodyData}
        style={{ width: '12rem', height:'50vh', padding: '1rem' }}
        onClick={handleBodyPartsClick}
        type={type}
        highlightedColors={["#87CEEB", "#4169E1", "#FF5733"]}
      />
  
      <div>
        {!!bodyData.length && bodyData.map(part => {
          switch (part.frequency) {
            case 1:
              return <p>{part.name}: sore</p>
            case 2:
              return <p>{part.name}: strained</p>
            case 3:
             return <p>{part.name}: injured</p>
          }
        })}
      </div>
   </div>
  </div>
}

export default BodyParts