import gql from "graphql-tag";

export const MUTATION_ACTIVITY_LOG = gql`
  mutation AddActivityLog($input: NewActivityLogInput!) {
    addActivityLog(input: $input) {
      id
      activity
      title
      description
      metaData
      createdAt
    }
  }
  `