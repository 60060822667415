export const firebaseConfig = {
  apiKey: "AIzaSyCpkf4BV7D1EDAym4ACKOok3M7nModrYE4",
  authDomain: "serious-trainer.firebaseapp.com",
  projectId: "serious-trainer",
  private_key_id: process.env.FIREBASE_PRIVATE_KEY_ID,
  // appId: "APP_ID",
  // The value of `databaseURL` depends on the location of the database
  // databaseURL: "https://DATABASE_NAME.firebaseio.com",
  // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  // measurementId: "G-MEASUREMENT_ID",
  // storageBucket: "PROJECT_ID.appspot.com",
  // messagingSenderId: "SENDER_ID",
};

export default firebaseConfig;