import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/joy/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
import { Button } from "@mui/material";
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import ProgramsSection from "../organisms/ProgramsSection";

const clients = [
  // create 5 unique objects
  // use faker to generate random images
  // use faker to generate random names
  // use faker to generate random activities
  // dt_entry should be unique
  { img: faker.image.avatar(), name: faker.name.findName(), activity: 'gym', dt_entry: '18/05/2023' },
  { img: faker.image.avatar(), name: faker.name.findName(), activity: 'basketball', dt_entry: '10/01/2023' },
  { img: faker.image.avatar(), name: faker.name.findName(), activity: 'running', dt_entry: '7/06/2023' },
  
]
const bookings = [
  // create 5 unique objects
  // use faker to generate random images
  // use faker to generate random names
  // use faker to generate random activities
  // dt_entry should be unique
  {name: faker.name.findName(), reason: 'tight lower back', dt_entry: '6/12/2023' },
  {name: faker.name.findName(), reason: 'knee pain', dt_entry: '10/05/2023' },
  {name: faker.name.findName(), reason: 'general physio', dt_entry: '19/05/2023' },
  
]

const SpecialistView = ()=> {
  React.useEffect(() => {
    document.title = 'Serious Trainer | Specialist View';
  }, []);

  const navigate = useNavigate()

  return(
    <Box sx={{background: 'whitesmoke', height:'100%'}}>
      <Box sx={{ padding: '12px 24px 24px', display: 'flex', flex: 1,
      justifyContent: 'space-between' }}>
        <ArrowBackIcon sx={{cursor:"pointer"}} onClick={()=>{ navigate(-1) }} />
      </Box>

      <Box>
        <Box sx={{display: 'flex', backgroundSize: 'cover', backgroundColor:'black', flexDirection: 'column', justifyContent: 'end', padding: '12px 24px'}}>
          <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
            Lab: Physio
          </Typography>
        </Box>
        
        {/* athletes linked to specialist */}
        <Box sx={{margin: '24px'}}>
          <Typography sx={{margin: '0 0 12px'}} variant="h5" component="div">
            Clients
          </Typography>

          {clients.map((client, index) => {
            return <Paper elevation={3} key={index} sx={{display:'flex', flexFlow: 'wrap',
            justifyContent: 'space-around', padding: '0 24px', alignItems: 'center', cursor: 'pointer'}} onClick={()=>{ navigate(`/athlete/`)}}>
            {/* PICTURE rounded */}
            <img src={client.img} alt="athlete" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
            {/* NAME */}
            <Typography variant="body1" component="div" sx={{margin: ' 24px', flex: 1}}> 
              {client.name}
            </Typography>
            {/* activity */}
            <Typography variant="body1" component="div" sx={{margin: ' 24px', flex: 1}}> 
              {client.activity}
            </Typography>
            {/* ACTIVE STATUS */}
            <Typography variant="body2" component="div" sx={{margin: ' 24px'}}> 
              last entry: {client.dt_entry}
            </Typography>
          </Paper>
          })}
        </Box>

        {/* appointments */}
        <Box sx={{margin: '24px'}}>
          <Typography sx={{margin: '0 0 12px'}} variant="h5" component="div">
            Appointments
          </Typography>

          {bookings.map((client, index) => {
            return <Paper elevation={3} key={index} sx={{display:'flex', flexFlow: 'wrap', justifyContent: 'space-around', padding: '0 24px', alignItems: 'center', cursor: 'pointer', backgroundColor:"#70809021"}}>
            {/* NAME */}
            <Typography variant="body1" component="div" sx={{margin: ' 24px', flex: 1}}> 
              {client.name}
            </Typography>
            {/* activity */}
            <Typography variant="body1" component="div" sx={{margin: ' 24px', flex: 1}}> 
              {client.reason}
            </Typography>
            {/* ACTIVE STATUS */}
            <Typography variant="body2" component="div" sx={{margin: ' 24px'}}> 
              {client.dt_entry}
            </Typography>
            {/* amend booking */}
            <Button variant="outlined" sx={{height: ' 24px'}}>
              Amend
            </Button>
          </Paper>
          })}
        </Box>

        {/* My plans and their stats */}
        <Box sx={{margin: '24px'}}>
          <ProgramsSection title="Active program" />
        </Box>
      </Box>
    </Box>
  )
}

export default SpecialistView