import React from "react"
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {getCurrentUser} from '../../services'
import ActivityCard from "../atoms/ActivityCard";

const QUERY_ACTIVITY_LOG = gql`
  query ActivityLog($input: ActivityLogInput!) {
    activityLog(input: $input) {
      id
      title
      description
      createdAt
      activity
      activityID
      metaData
    }
  }
`

const RecentActivity = () => {

  const {data, loading, error } = useQuery(QUERY_ACTIVITY_LOG, {variables: {input: { userId: getCurrentUser().id}}})
  const { activityLog } = data || {}

  console.log('activityLog', activityLog)

  if(loading) {
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px', margin: '24px', background: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 4px 0px #8a8a8a8a' }}>
    <Box sx={{height: '20px', width: '100px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />

    <Box sx={{display: 'flex', gap: '10px', paddingBottom: '15px', overflowX: 'overlay'}}>
    <Box sx={{height: '100px', width: '100px', backgroundColor: 'lightgrey', marginRight: '10px', borderRadius: 2}} />
    </Box>
  </Box> 
  }

  if (error) {
    return <p>error</p>
  }

  return (
  <>
    {activityLog.length ? <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px', margin: '24px', background: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 4px 0px #8a8a8a8a' }}>
      <Typography variant="h6" component="div" >
        Recent Activity <Typography sx={{display: 'inline'}} variant="body1" component="div" fontWeight={500} fontSize={12} color={"slategrey"} >- {'keep up the great work :)'}</Typography>
      </Typography>
  
      <Box sx={{display: 'flex', gap: '10px', paddingBottom: '15px', overflowX: 'overlay'}}>
        {/* sort so latest ones appear first */}
        { activityLog.sort((a: any, b: any)=> {if (a.createdAt > b.createdAt) {return -1} else {return 1}}).map((item: any, i: number)=> <ActivityCard key={item.id} title={item.title} description={item.description} metaData={item.metaData} createdAt={item.createdAt} activity={item.activity} />) }
      </Box>
    </Box> : ""}
  </>
  )
}

export default RecentActivity