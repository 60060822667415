import React from "react";
import LogSection from '../molecules/LogSection'
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { faker } from '@faker-js/faker';
import ProgramsSection from "../organisms/ProgramsSection";
import SessionsSection from "../organisms/SessionsSection";
import RecentActivitySection from "../organisms/RecentActivitySection";
import EditIcon from '@mui/icons-material/Edit';
import Layout from "../organisms/Layout";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Loader from '../atoms/Loader'
import { Button } from "@mui/material";
import {AiChatContext} from '../molecules/BottomNav'

const tm_1 = faker.image.avatar()
const tm_2 = faker.image.avatar()
const tm_3 = faker.image.avatar()

const QUERY_MY_DATA = gql`
  query getMyData {
    me {
      id
      firstName
      lastName
      email
      avatar
      activities
      role
      logCount
    }
}
`

const Profile = ()=>{
  const {data, loading, error } = useQuery(QUERY_MY_DATA)
  const navigate = useNavigate()

  React.useEffect(() => {
    document.title = 'Serious Trainer | Profile';
  }, []);

  let me = data ? data.me : null

  if(loading) {
    return <Box>
    <Loader/>

    <Box sx={{display: 'flex', justifyContent: 'center', padding: '24px'}}>
      <Box 
        style={{width: '100px', height: '100px', borderRadius:'50%', border: 'solid lightgrey', backgroundColor: 'lightgrey'}}
      />
    </Box>

    <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <Box sx={{height: '20px', width: '100px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
      <Box sx={{height: '20px', width: '200px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
      <Box sx={{height: '20px', width: '300px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
      <Box sx={{height: '20px', width: '50px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
    </Box>

    <Box sx={{display: 'flex', justifyContent: 'space-around', margin: '24px 20%'}}>
      <Box sx={{height: '40px', width: '50px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
      <Box sx={{height: '40px', width: '50px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
      <Box sx={{height: '40px', width: '50px', backgroundColor: 'lightgrey', marginBottom: '10px'}} />
    </Box>
  </Box>
  }

  if (error || !me) {
    navigate('/login')
    return null
  }

  return <Layout>
    <Box sx={{display: 'flex', justifyContent: 'center', padding: '24px'}}>
      <img 
        style={{width: '100px', height: '100px', borderRadius:'50%', border: 'solid lightgrey'}}
        src={me?.avatar}
        alt="user-img"
      />
    </Box>

    <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <Typography variant="h6" component="div" >
        {`${me.firstName} ${me.lastName}`}
      </Typography>
      <Typography variant="h7" fontWeight={500}>
        {me.activities &&` Activities: ${me.activities}`}
      </Typography>
      <Typography sx={{cursor:"pointer", marginTop: '10px'}} variant="body2" fontWeight={500} onClick={()=>{ navigate('/p/edit')}}>
      edit profile <EditIcon fontSize="6" /> 
      </Typography>

    </Box>

    <Box sx={{display: 'flex', justifyContent: 'space-around', margin: '24px 20%'}}>
      <ProfileStats value={me?.logCount ?? 0} label='Logs' onClick={()=>{ navigate('/p/logs')}}/>
      {/* <ProfileStats value={3} label='Programs'/> */}

      <ReactTooltip
        id="team-tooltip"
        place="top"
        content='Feature coming soon' />

      <Box data-tooltip-id="team-tooltip" 
        sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', cursor:'pointer'}}
      >
        <AvatarGroup>
          <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={tm_1} />
          <Avatar sx={{ width: 24, height: 24 }} alt="Travis Howard" src={tm_2} />
          <Avatar sx={{ width: 24, height: 24 }} alt="Agnes Walker" src={tm_3} />
        </AvatarGroup>
        <Typography variant="body1" fontWeight={700} fontSize={12} color={"slategrey"}>
          Team
        </Typography>
      </Box>
    </Box>

    {/* log session or body */}
    <LogSection/>

    {/* recent activity section */}
    <RecentActivitySection />


    {/* session section */}
    <Box sx={{margin: '24px'}}>
      <SessionsSection />
    </Box>
    {/* programs section */}
    <Box sx={{margin: '24px'}}>
      <ProgramsSection />
    </Box>

  </Layout>
 
}

export default Profile

const ProfileStats = ({value, label, onClick= ()=>{}}) => {
 return (
  <Box  
    sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', cursor:'pointer'}}
    onClick={onClick}
  >
    <Typography variant="body1" fontWeight={800}>
      {value}
    </Typography>
    <Typography variant="body1" fontWeight={700} fontSize={12} color={"slategrey"}>
      {label}
    </Typography>
  </Box>
 )
}