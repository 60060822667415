import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { queryExercises } from '../../api'
import ExerciseCard from "../atoms/ExerciseCard";
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import CreateExercise from "./CreateExercise";

const QUERY_EXERCISES = gql`
  query ExercisesPartialSearch($input: SearchInput) {
  exercisesPartialSearch(input: $input) {
    id
    title
    description
    type
    skillLevel
    image
    video
  }
}
`

interface SearchExerciseProps {
  onChange: (id: string, label: string) => void
  onClose: () => void
  initialSearchTerm?: string
}

const SearchExercise = ({onChange, onClose, initialSearchTerm} : SearchExerciseProps )=> {
  const [search, setSearch] = React.useState(initialSearchTerm || '')
  const [exercises, setExercises] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const { data: savedExercisesData , loading: savedExercisesLoading, error: savedExercisesError } = useQuery(QUERY_EXERCISES, {
    variables: { "input": { "search": search } },
    skip: !search,
  })
  const [createNewExercise, setCreateNewExercise] = React.useState(false)

  // render form with search input
  return (
   <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
      <Box 
        component="form"
        sx={{
          display: 'flex',
          gap: '10px',
        }}>
        <TextField
          id="search-exercise"
          label="Exercise search"
          placeholder="bicep curls, etc." 
          defaultValue={search}
          onChange={(e)=>{ setSearch(e.target.value) }}
        />
        <Button 
          variant="contained" 
          type="submit" 
          onClick={(e)=> {
            e.preventDefault()
            e.stopPropagation()

            console.log('search', search)
            setLoading(true)
            setExercises([])

            queryExercises({name: search})
            .then((data)=>{
              setExercises(data)
              console.log('queryExercises', data)

              setLoading(false)
            })
          }}
        >Search</Button>
        <Button 
        variant="outlined" 
        type="button" 
        onClick={()=> {
          onClose()
        }}><CloseIcon/></Button>
        <Button 
        aria-label="new exercise"
        variant="outlined" 
        type="button" 
        onClick={()=> {
          setCreateNewExercise(!createNewExercise)
        }}><CreateIcon/> Create</Button>

      </Box>

      {createNewExercise ? <CreateExercise onClose={(val)=>{setCreateNewExercise(val)}} onSubmit={()=> {setCreateNewExercise(false)}}/> : null}

      {!createNewExercise && savedExercisesLoading || loading ? <Typography variant="h6">Loading...</Typography> : ""}
      
      {!createNewExercise && savedExercisesData?.exercisesPartialSearch?.length ? <Typography variant="h6">Saved Exercises</Typography> : ""}
      {!createNewExercise && savedExercisesData?.exercisesPartialSearch?.length ? savedExercisesData.exercisesPartialSearch?.map((exercise: any)=>{
        return (
          <Box>
            <ExerciseCard 
              key={uuidv4()} 
              title={exercise.title} 
              onClick={(id, label)=>{onChange(id, label)}}
              description={exercise.description} 
              type={exercise.type} 
              difficulty={exercise.skillLevel?.toUpperCase()} 
              equipment={exercise.equipment} 
              targetMuscles={[exercise.muscle]}
              image={exercise.image}
              video={exercise.video}
              id={exercise.id}
              newExercise={false}
               />
          </Box>
        )
      }) : null }

      {!createNewExercise && exercises?.length ? <Typography variant="h6">Create exercise</Typography> : ""}
      {!createNewExercise && exercises?.length ? exercises.map((exercise: any, i)=>{
        return (
          <Box>
            <ExerciseCard 
              key={uuidv4()} 
              title={exercise.name} 
              onClick={(id, label)=>{onChange(id, label)}}
              description={exercise.instructions} 
              type={exercise.type} 
              difficulty={exercise.difficulty?.toUpperCase()} 
              equipment={exercise.equipment} 
              targetMuscles={[exercise.muscle]} 
              newExercise={true}
              />
          </Box>
        )
      }) : null}
   </Box>
  )
}

export default SearchExercise