import React from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {

  return <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', position: "absolute", width: '100vw', paddingTop: '100px' }}>
  <CircularProgress />
</Box>
}

export default Loader
