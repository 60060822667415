import React from "react"
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import default_bg_image from '../../img/no-image.png'

const QUERY_SESSIONS = gql`
  query Sessions {
  sessions {
    id
    title
    description
    image
    skillLevel
    duration
  }
}
`

interface SessionWorkoutProps {
}

const SessionWorkout = ({} : SessionWorkoutProps) => {

  const {data, loading, error } = useQuery(QUERY_SESSIONS)

  const navigate = useNavigate()

  if(loading) {
    return <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '24px'}}>
    {/* session selector  */}
     {[1,2,3].map(() => {
       return (
         <Box sx={{display:"flex", gap: "14px", borderRadius: '10px', padding: '10px', background: "lightgrey", height: '140px', width: '320px', boxShadow: '4px 4px 10px 0px #dae2e2' }} />
       )
     })}
   </Box>
  }

  if (error) {
    return <p>error</p>
  }

  const handleSessionClick = (id: number)=>{ 
    navigate(`/p/session/${id}`)
  }

  return (
    <Box>
      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '24px'}}>
       {/* session selector  */}
        {data.sessions.map((session : any) => {
          return (
            <Box sx={{display:"flex", gap: "14px", borderRadius: '10px', padding: '10px', background: "whitesmoke", width: '320px', boxShadow: '4px 4px 10px 0px #dae2e2' }} key={session.id} onClick={()=>handleSessionClick(session.id)}>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <img src={session.image || default_bg_image} alt={session.title} style={{width:115, borderRadius: '10px'}} />
                <Typography variant="body2"> <AccessAlarmsOutlinedIcon/> {session.duration} minutes</Typography>
              </Box>
              <Box sx={{maxWidth: "270px", display: 'flex', flexDirection: 'column', gap: '4px'}}>
                <Typography variant="subtitle1">{session.title}</Typography>
                <Typography variant="caption">{session.description}</Typography>
              </Box>
            </Box>
          )
        })}
      </Box>

    </Box>
  )
}

export default SessionWorkout