import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Select, MenuItem } from "@mui/material";
import { BODY_PARTS, Options, skillLevelOptions  } from "../../utils/fixtures";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '../atoms/Alert'
import ImageFieldWithSearch from "./ImageFieldWithSearch";
import VideoFieldWithSearch from "./VideoFieldWithSearch";

export const MUTATION_EXERCISE = gql`
  mutation AddExercise($input: NewExerciseInput!) {
  addExercise(input: $input) {
    id
    title
  }
}
`
interface ExerciseDefaultValuesProps {
  "title": string
  "description": string
  "image": string
  "video": string
  "targetMuscles": string[] | null[]
  "skillLevel": string
  "equipment": string
  "private": boolean
  "type": string
}

const ExerciseDefaultValues : ExerciseDefaultValuesProps = {
  "title": '',
  "description": '',
  "image": '',
  "video": '',
  "targetMuscles": [null],
  "skillLevel": 'ANY',
  "equipment": '',
  "private": false,
  "type": "",
} 

interface CreateExerciseProps {
  defaultValue?: ExerciseDefaultValuesProps
  targetMusclesMultiSelect?: boolean
  onSubmit?: ((id: string, label: string ) => void )
  onClose?: ((val: boolean ) => void )
}

const CreateExercise = ({ defaultValue = ExerciseDefaultValues, targetMusclesMultiSelect = true, onSubmit, onClose = ()=>{} } : CreateExerciseProps)=> { 
  const [toast, setToast] = useState<{open: boolean, message: string, severity: 'error' | 'success'}>({open: false, message: '', severity: 'success'})

  const [addExercise, {data: sessionData, loading: sessionLoading, error: sessionError }] = useMutation(MUTATION_EXERCISE)

  const targetMusclesOptions : Options = Object.keys(BODY_PARTS)?.map((limb: string )=> {
    // @ts-ignore
    return { label: limb, id: BODY_PARTS[limb] }
  })

  const { register, setValue, handleSubmit, getValues, control } = useForm({
    defaultValues: defaultValue
  })
  

  return (
    <div style={{background: 'whitesmoke', height: '100%'}}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={()=>{}}>
          <Alert onClose={()=>{ setToast({...toast, open: false})}} severity={toast.severity} sx={{ width: '100%' }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Stack>

      <Box sx={{ margin: '24px', backgroundColor: 'white', padding: '12px', borderRadius: '12px'}}>
        <Box sx={{display: 'flex'}}>
          <Typography  sx={{margin: '12px 0'}} variant="h5" component="div">
            New Exercise
          </Typography>
          <Box sx={{display: "flex", flex: 1, justifyContent: 'flex-end', alignItems: 'center',  paddingRight: '12px'}}>
          </Box>
        </Box>

        <Divider/>

        <Box 
          sx={{display:'flex', gap: '12px', marginTop: '12px', flexDirection: 'column'}}
          component="form"
          >
          <TextField
            required
            id="title"
            label="Title"
            placeholder="title" 
            {...register("title", {required: true})}
          />
          <TextField
            required
            id="description"
            label="Description"
            placeholder="description" 
            {...register("description", {required: true})}
          />
          <ImageFieldWithSearch onChange={(val)=> setValue("image", val)} imageUrl={getValues("image")} initialSearchTerm={getValues("title")}/>
          <VideoFieldWithSearch onChange={(val)=> setValue("video", val)} videoUrl={getValues("video")} initialSearchTerm={getValues("title")}/>

          <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
            Target muscles
          </Typography>
         { targetMusclesMultiSelect ? 
          <Controller
            control={control}
            name="targetMuscles"
            rules={{ required: 'true' }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                multiple
                disablePortal
                id="targetMuscles"
                options={targetMusclesOptions}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select target muscles" />}
                onChange={(d, val)=>{ 
                  console.log('change targetMuscles', val)
                  // @ts-ignore
                  setValue('targetMuscles', val.map((item: any)=>item.id))
                }}
              />
            )}/> : 
            <TextField
              id="targetMuscles"
              label="Muscle"
              placeholder="targetMuscles" 
              {...register("targetMuscles", {required: false})}
            />}

          <Typography  sx={{margin: '4px 0 0'}} variant="h6" component="div">
            Skill Level
          </Typography>
          <Controller
            control={control}
            name="skillLevel"
            rules={{ required: 'true' }}
            render={({ field: { onChange, value } }) => (
              <Select
                labelId="skillLevel-select-label"
                id="skillLevel"
                value={getValues('skillLevel')}
                label="skill level"
                onChange={(e)=>{ 
                  console.log('change skillLevel', e)
                  // @ts-ignore
                  setValue('skillLevel', e?.target?.value)
                }}
                required={true}
              >
                {skillLevelOptions.map((skill)=>{
                  return <MenuItem value={skill.id} key={skill.id}>{skill.label}</MenuItem>
                })}
              </Select>
            )}
          />

          <TextField
            id="equipment"
            label="Equipment"
            placeholder="dumbells, kettlebells, etc." 
            {...register("equipment")}
          />

          <FormControlLabel control={
            <CheckBox
              value={getValues('private')}
              id="private"
              onChange={(e)=>{ setValue('private', e.target.checked) }}
            />} 
            label="Private"
          />

          <Box>
            <Button variant="contained" type="submit"
              onClick={handleSubmit(data => {
                  
                console.log('exercise submit data', data)
    
                addExercise({
                  variables: {
                    "input": {
                      ...data,
                    }
                  }
                })
                .then(({data})=>{
                  onSubmit && onSubmit(data.addExercise.id, data.addExercise.title)
    
                  console.log('success heres your exercise - ', data)
                  setToast({open: true, message: 'Exercise created successfully', severity:"success"})
                })
                .catch((e)=>{
                  console.log(e)
                  setToast({open: true, message: 'Error creating exercise, try again.', severity:"error"})
                })
              })}
            >Save</Button>
            <Button variant="contained" color="error" onClick={()=>{ onClose(false) }}>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CreateExercise