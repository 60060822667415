import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getCurrentUser } from '../../services'
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CreateProgram from '../organisms/CreateProgram'
import CreateSession from '../organisms/CreateSession'
import CreateExercise from '../organisms/CreateExercise'
import Layout from "../organisms/Layout";


type Create = 'program' | 'session' | 'exercise'

const Create = ({title = "Create" })=> {
  const { id, type } = useParams()
  const [state, setState] = useState<Create>("program")

  React.useEffect(() => {
    document.title = 'Serious Trainer | Create';
  }, []);
  
  const navigate = useNavigate()
  
  const handleCreateButton = (type: Create)=> {
    setState(type)
  }
  console.log('id', id, 'type', type)

  return (
    <Layout>
      <div style={{background: 'whitesmoke', height: '100vh'}}>
        <Typography variant="h5" component="div" style={{margin: '0 24px'}}>
          {title}
        </Typography>
        
        <Box sx={{ margin: '24px', cursor:'pointer' }}>
          <Button variant={state === "program" ? 'outlined' : 'contained'} onClick={()=> handleCreateButton('program')}>Program</Button>
          <Button variant={state === "session" ? 'outlined' : 'contained'} onClick={()=> handleCreateButton('session')}>Session</Button>
          {/* <Button variant={state === "exercise" ? 'outlined' : 'contained'} onClick={()=> handleCreateButton('exercise')}>Exercise</Button> */}
        </Box>
  
        <Divider/>
  
        { state === 'program' && <CreateProgram /> }
        { state === 'session' && <CreateSession /> }
        {/* { state === 'exercise' && <CreateExercise /> } */}
      </div>
    </Layout>
  )
}

export default Create