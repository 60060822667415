import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Profile from "./components/pages/Profile";
import Logs from "./components/pages/Logs";
import Program from "./components/pages/Program";
import Session from "./components/pages/Session";
import Create from "./components/pages/Create";
import Log from "./components/molecules/Log";
import Login from "./components/molecules/Login";
import SignUp from "./components/molecules/SignUp";
import SpecialistView from "./components/pages/SpecialistView";
import AthleteView from "./components/pages/AthleteView";
import ProfileEdit from "./components/pages/ProfileEdit";
import SearchSessions from "./components/pages/SearchSessions";
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './auth'

import css from "./App.css";

const PageDontExist = ()=> {
  React.useEffect(() => {
    document.title = 'Serious Trainer | Page not found';
  }, []);

  return ( <div>
  <h1>DNS</h1>
  <p>Sorry page doesn't exist</p>
</div>)}

function App() {

initializeApp(firebaseConfig);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"  element={<Login/>}/> 
        <Route path="/login"  element={<Login/>}/> 
        <Route path="/login/:email"  element={<Login/>}/> 

        <Route path="/signup" element={<SignUp/>}/>

        <Route path="/w/create" element={<Create/>}/>
        <Route path="/w/edit/:id&:type" element={<Create title="Edit"/>}/>

        <Route path="p/edit" element={<ProfileEdit/>}/>
        <Route path="p/logs" element={<Logs/>}/>
        <Route path="p/log/:id" element={<Log/>}/>

        <Route path="p/program/:id" element={<Program/>}/>
        <Route path="p/session/:id" element={<Session/>}/>
        
        <Route path="/p/*" element={<Profile/>}/>

        <Route path="/s/explore-sessions" element={<SearchSessions/>}/>
        <Route path="/s/*" element={<SpecialistView/>}/>

        <Route path="/athlete/*" element={<AthleteView/>}/>
        {/* todo: logic to detect if user is logged in - redirect where needs be */}

        <Route path="/*" element={<PageDontExist/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;