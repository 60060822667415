import { ApolloClient } from '@apollo/client/core'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client'
// import gql from 'graphql-tag'
import { getToken } from "./services"

const env = process.env.NODE_ENV
// update this when you're not home - or use 'http://localhost:4000/'
const localUri = 'http://192.168.0.18:4000/'

const uri = env === "production" ?
'https://us-central1-serious-trainer.cloudfunctions.net/graphql/' : localUri

const cache = new InMemoryCache()
const link = new HttpLink({
  // TODO - make this dynamic later
  uri: uri

  // so it runs on the network
  // uri: 'http://192.168.0.15:4000'
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});

// caching is kinda annoying right now - so turning it off
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
  defaultOptions
})

export default client