import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

const QUERY_YOUTUBE_VIDEOS = gql`
  query SearchYoutube($input: YouTubeVideoInput!) {
    searchYoutube(input: $input) {
      id
      title
      description
      url
      thumbnails {
        url
      }
      bestThumbnail {
        url
      }
    }
  }
`
type Thumbnail = {
  url: string
}

interface YouTubeVideo {
  id: string,
  title: string,
  description: string,
  url: string,
  thumbnails: [Thumbnail]
  bestThumbnail: Thumbnail
}

interface SearchImageProps {
  onChange: (url: string, alt?: string) => void
  onClose: () => void
  initialSearchTerm?: string
}

const SearchImage = ({onChange, onClose, initialSearchTerm} : SearchImageProps )=> {
  const [search, setSearch] = React.useState(initialSearchTerm || '')
  const [selectedVideo, setSelectedVideo] = React.useState({url: '', openDialog: false})
  const [triggerSearch, setTriggerSearch] = React.useState('')

  const { data , loading, error } = useQuery<{searchYoutube: [YouTubeVideo]}>(QUERY_YOUTUBE_VIDEOS, {
    variables: { "input": { "search": triggerSearch } },
    skip: !triggerSearch,
  })

  return (
  <Box 
    sx={{
      background: 'aliceblue',
      borderRadius: '5px',
      padding: '20px'
    }}
  >

    <Box 
      component="form"
      sx={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <TextField
        id="search-video"
        label="video search"
        placeholder="leg curls, etc." 
        defaultValue={search}
        onChange={(e)=>{ setSearch(e.target.value) }}
      />
      <Button 
        variant="contained" 
        type="submit" 
        onClick={(e)=> {
          e.preventDefault()
          
          console.log('youtube search:', search)
          setTriggerSearch(search)

        }}>Search</Button>
      <Button 
        variant="outlined" 
        type="button" 
        onClick={()=> {
          onClose()
        }}><CloseIcon/></Button>
    </Box>

    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))', gap: '10px', cursor: 'pointer'
    }}>

      {loading && <Typography variant="h6">Loading...</Typography>}
      
      {error && <Typography variant="h6">Error...</Typography>}
      
      
      {data && data?.searchYoutube?.map((video : YouTubeVideo, i) => {
        const { id, thumbnails, title }  = video
        const thumb = thumbnails?.[0]?.url || ''
        const url = `https://www.youtube.com/embed/${id}`

        if(id === null) return 
  
        return (
          <Box key={id + i} >
            <Box
              sx={{
                width: 'auto',
                height: '130px',
                overflowY: 'hidden'
              }}
            >
              <PlayCircleIcon 
                sx={{
                  position: 'relative',
                  right: '-80px',
                  top: '65px',
                  color: 'white',
                  fontSize: '16px'
                }}
              />
              <img src={thumb} alt={title}
                onClick={()=>{ setSelectedVideo({url: url, openDialog: true}) }}   
                />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button 
                variant="outlined" 
                sx={{width: '-webkit-fill-available'}}
                onClick={()=>{ 
                  onChange(url) 
                }} 
              > select </Button>
            </Box>
          </Box>
        )
      })}
    </Box>

    <Modal
        open={selectedVideo.openDialog}
        onClose={() => setSelectedVideo({...selectedVideo, openDialog: false})}
        aria-labelledby="modal-image"
        onClick={()=>{ setSelectedVideo({...selectedVideo, openDialog: false}) }}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px'
          }}
        >

        <iframe width="100%" height="315" src={selectedVideo?.url} style={{maxWidth: "560px", marginTop: "12px"}} title="Serious trainer player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
        </Box>
    </Modal>


  </Box>
  )
}

export default SearchImage