import React, {useState} from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import GoogleIcon from '@mui/icons-material/Google';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import { saveCurrentUser } from '../../services'
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput'
import logoSrc from '../../img/logo.png'
import { getAuth, GoogleAuthProvider, getRedirectResult, signInWithPopup } from "firebase/auth";
import { LOGIN } from "./Login";
import Loader from '../atoms/Loader'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roles = [
  'ATHLETE',
  'COACH',
  'GUEST'
];

function getStyles(role, rolesSelected, theme) {
  return {
    fontWeight:
      rolesSelected.indexOf(role) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SIGNUP = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      user {
        id
        email
      }
    }
  }
`

const SignUp = ()=> {
  const [signup, {data, loading, error}] = useMutation(SIGNUP, { errorPolicy: 'all' })
  const [login] = useMutation(LOGIN, { errorPolicy: 'all' })
  const navigate = useNavigate()
  const theme = useTheme();
  // need this because useForm cant handle multi-select
  const [rolesSelected, setRole] = useState(['ATHLETE'])
  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
     role: rolesSelected
    }
  });
  const [signUpMethod, setSignUpMethod] = React.useState(null)

  React.useEffect(() => {
    document.title = 'Serious Trainer | Sign up';
  }, []);

  async function stateChangeCb(res) {
    const user = res._tokenResponse
    if (user) {

      const signUpData = await signup({
        variables: {
          "input": {
            "email": user.email,
            "password": user.localId, 
            "firstName": user.firstName,
            "lastName": user.lastName,
            "avatar": user.photoUrl,
            "role": ["ATHLETE"],
          }
        }
      })

      Promise.resolve([signUpData, res.user])
      .then(([data, user]) => {  
        // if user null do nothing
        if (!data || !user) return
  
        return login({
          variables: {
            "input": {
              "email": user.email,
              "password": user.uid,
              "accessToken": user.accessToken,
            }
          }
        })
      })
      .then(({ data }) => {
        if (data) {
          const path = (data.signIn.user.firstName + '-' + data.signIn.user.lastName).toLowerCase()
      
          saveCurrentUser(data.signIn);
          navigate(`/p/${path}`) 
        }
      })
      .catch((error) => {
        console.log('error', error)
      });

    } else {
      console.log('observer - signed out')
      // User is signed out
      // 
    }
  }

  
  const onSubmit = data => {
    signup({
      variables: {
        "input": data
      }
    })
    .then(({data}) => {
      if (data) {
        const email = (data.signUp.user.email)

        navigate(`/login/${email}`) 
      }
    })
    .catch(e => {
      console.log('e signup', e.message)
    })
  };

  const handleChange = (event) => {
    let { target: { value },} = event;
    // On autofill we get a stringified value.
    // value = typeof value === 'string' ? value.split(',') : value,

    setRole(value)
    setValue('role', value)

  };

  if (error) (<p>Error soz!</p>)

  if(loading) {
    return <Loader/>
  }

  return <div style={{    
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',}}>
  <img alt="logo" src={logoSrc} style={{width: "100px", 'marginBottom': '30px'}} />
  
  {error && (
    <div style={{background: "lightcoral", padding: 5, margin: 10, borderRadius: 5}}>{error.graphQLErrors.map(({ message }, i) => (
      <p key={i}>{message}</p>
    ))}
    </div>
  )}

  {signUpMethod === "emailPassword" ?<Box
    component="form"
    style={{ display: 'flex', flexDirection: 'column' }}
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
    }}
    autoComplete="off"
    onSubmit={handleSubmit(onSubmit)}
  >
    {error && (
      <p>{error.graphQLErrors.map(({ message }, i) => (
        <span key={i}>{message}</span>
      ))}
      </p>
    )}
    
    <TextField
      required
      id="firstName"
      label="First name"
      placeholder="firstName" {...register("firstName", {required: true})}
    />
    <TextField
      required
      id="lastName"
      label="Last name"
      placeholder="lastName" {...register("lastName", {required: true})}
    />
    <Select required
      multiple
      labelId="role-label"
      id="role"
      value={rolesSelected}
      label="Role"
      onChange={handleChange}
      placeholder="role"
      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={value} />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
      >
        {roles.map((role) => (
          <MenuItem
            key={role}
            value={role}
            style={getStyles(role, rolesSelected, theme)}
          >
            {role}
          </MenuItem>
        ))}
    </Select>
    <TextField
      required
      id="email"
      label="Email"
      type="email"
      placeholder="email" {...register("email", {required: true})}
    />
    <TextField
      id="st-password"
      label="Password"
      type="password"
      placeholder="password" {...register("password", {required: true})}
    />
    <TextField
      id="avatar"
      label="Avatar url"
      type="url"
      placeholder="www.avatar.com ..." {...register("avatar")}
    />
    <Button variant="contained"
      type="submit"
      endIcon={loading ? <p>⏱</p> : null}>
      Sign up
    </Button>

    </Box> : null}

    <br/>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {signUpMethod !== "emailPassword" && <Button onClick={()=> setSignUpMethod("emailPassword")} variant="outlined" sx={{width: "266px"}} >Sign up with form</Button>}
      <br/>
      <Button
        onClick={() => {
          const provider = new GoogleAuthProvider();
          const auth = getAuth();
          signInWithPopup(auth, provider)
          .then(stateChangeCb)
        }}
        variant="outlined"
        sx={{width: "266px"}}
      >
        <GoogleIcon /> {" "}
        <span style={{paddingLeft: 10}} >Sign up with Google</span>
      </Button>    
      
      <Box sx={{display: 'flex', padding: '30px 0px', alignItems: 'center'}}>
        <Box sx={{width: '110px', marginRight: '10px', height: '2px', background: 'lightgrey'}}/>
        <Box sx={{margin: [0, '15px']}}>or</Box>
        <Box sx={{width: '110px', marginLeft: '10px', height: '2px', background: 'lightgrey'}}/>
      </Box>
  
      
      <p>Have an account? <span style={{ textDecoration: 'underline', color: 'green', cursor: 'pointer'}} onClick={()=>{ navigate('/login')}}>Login</span></p>
    </Box>
</div>
}

export default SignUp