import React from "react"
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AiImgSrc from '../../img/st-ai.png'
import { useMutation } from "@apollo/client";
import Loader from "../atoms/Loader";
import Skeleton from "../atoms/Skeleton";
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { marked } from 'marked';
import * as DOMPurify from 'dompurify';
import { useNavigate } from "react-router-dom";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RunCircleIcon from '@mui/icons-material/RunCircle';

const QUERY_MY_DATA = gql`
  query getMyData {
    me {
      avatar
      firstName
    }
  }
  `

const QUERY_AI_CHAT_MESSAGES= gql`
  query AiChatMessages {
    aiChatMessages {
      content
      role
    }
  }
  `

const MUTATION_AI_CHAT_MSG = gql`
  mutation Mutation($input: ChatGptMessageInput) {
  createChatGptMessage(input: $input) {
    content
    role
  }
}
`

interface AiChatProps {
  onCloseChat?: () => void
  aiChatCommand?: 'session_generator' | null
}

const AiChat = ({ onCloseChat, aiChatCommand } : AiChatProps) => {

  const [createChatGptMessage, {data: mutationData, loading: mutationLoading, error: mutationError, client }] = useMutation(MUTATION_AI_CHAT_MSG, {errorPolicy: 'all'})
  const {data: profile, loading, error } = useQuery(QUERY_MY_DATA)
  const {data: savedChatMessages, loading: loadingSavedChatMessages, error: errorSavedChatMessages } = useQuery(QUERY_AI_CHAT_MESSAGES)

  const [chatUserInput, setChatUserInput] = React.useState(aiChatCommand === 'session_generator' ? '/session ' : '')

  const [chatMessages, setChatMessages] = React.useState([
    {role: 'assistant', content: "How can i help?"},
  ])
  // const [type, setType] = React.useState<'general' | 'video' | 'session_suggestion' | 'session_creator' >('general')
  const [type, setType] = React.useState(aiChatCommand === 'session_generator' ? 'session_suggestion' : 'general')
  const [commands, setCommands] = React.useState<boolean>(false)
  
  const userImg = profile?.me?.avatar
  const initial = profile?.me?.firstName?.charAt(0).toUpperCase()
  const scrollDiv = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if(mutationData?.createChatGptMessage) {
      setChatMessages([...chatMessages, {role: mutationData?.createChatGptMessage?.role, content: mutationData?.createChatGptMessage?.content}])

      setTimeout(() => {
        scrollDiv.current?.scrollIntoView({ behavior: "smooth" })
      }, 500);
    }

  }, [mutationData?.createChatGptMessage])

  React.useEffect(() => {   
    if(savedChatMessages?.aiChatMessages?.length) {
      setChatMessages([...chatMessages, ...savedChatMessages.aiChatMessages])

      setTimeout(() => {
        scrollDiv.current?.scrollIntoView({ behavior: "smooth" })
      }, 500);
    }
  }, [savedChatMessages?.aiChatMessages])

  console.log('mutationError', mutationError?.graphQLErrors)

  if (error) {
    return <p>error</p>
  }

  return (
    <Box sx={{
      height: '100%',
      overflowY: 'auto',
      border: '1px solid lightgray',
      borderRadius: '10px',
      background: 'whitesmoke',
    }}>
      
      {loading 
      ? <Box sx={{ padding: '20px'}}>
        <Loader/>
        <Skeleton width="200px" height="30px"/>
        <Skeleton width="400px" height="30px"/>
        <Skeleton width="300px" height="100px"/>
        <Skeleton width="300px" height="30px"/>
        <Skeleton width="200px" height="30px"/>
      </Box>
      :<Box >
          <Box sx={{width: '100%', backgroundColor: '#afafaf', borderRadius: '10px 10px 0 0', position: 'absolute'}}>
            <Box sx={{textAlign: 'right'}}>
              <Button sx={{fontSize: '20px', color: 'white', width: '30px', height: '30px'}}
                onClick={onCloseChat}>
                  <CancelIcon/>
              </Button>
            </Box>
          </Box>
          <Box sx={{padding: '40px 20px 20px', height: '100%'}}>
            {[...chatMessages].length > 0 && chatMessages.map((message, i) => {
              return  <ChatMessage 
                key={message.content + i} 
                from={message.role} 
                content={message.content} 
                userImg={userImg}
                initial={initial}
                setType={setType}
                createChatGptMessage={createChatGptMessage}
              />
            })}
            
            {/* loading message */}
            {mutationLoading ? <Typography sx={{}} fontSize={13}>
              Fetching your request, be right back ...
            </Typography> : ''}

            {/* Error message */}
            {mutationError?.graphQLErrors?.length ? <Typography sx={{color: 'red', fontSize: 13}}>
              {mutationError?.graphQLErrors[0]?.message}
            </Typography> : ''}

            {/* spacer */}
            <div style={{height: '100px'}}></div>
            
            {/* input container */}
            <Box 
              sx={{
                display: 'flex',
                marginTop: '40px', 
                width: '94%',    
                position: 'absolute',
                bottom: '55px',
                backgroundColor: "white",
                flexDirection: 'column',
              }}>
                {/* commands */}
              {commands && <Box
                sx={{
                  backgroundColor: '#e3e3e3',
                  padding: '10px',
                  border: '1px solid #5bb2b2',
                  borderRadius: '10px 10px 0 0',
                }}>
                <Box
                  className="command-row"
                  onClick={()=>{
                    setChatUserInput('/video ')
                    setType('video')
                    setCommands(false)
                  }}
                >
                  <Typography variant="body1"><PlayCircleIcon fontSize="medium" /> <b>/video [text]</b></Typography>
                  <Typography>Command . Search for youtube video</Typography>
                </Box>
                <Box
                  className="command-row"
                  onClick={()=>{
                    setChatUserInput('/session ')
                    setType('session_suggestion')
                    setCommands(false)
                  }}
                >
                  <Typography variant="body1"
                  ><RunCircleIcon fontSize="medium" /> <b>/session [describe, access history]</b></Typography>
                  <Typography>Command . Session . Get a session suggestion</Typography>
                </Box>
              </Box>}

              {/* input */}
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Button 
                  variant="outlined"
                  onClick={()=>{
                    setCommands(!commands)
                  }}
                  disabled={chatUserInput.length > 0}
                >
                  <AutoFixHighIcon sx={{width: '30px', height: '30px'}}/>
                </Button>
                <TextField
                  sx={{
                    width: '100%',    
                    background: 'white',
                  }}
                  id="userInput"
                  label="enter message"
                  placeholder="how can i help?"
                  value={chatUserInput}
                  onChange={(e)=>{
                    setChatUserInput(e.target.value)
  
                    if(e.target.value.startsWith('/video')) {
                      setType('video')
                    }
                    else if(e.target.value.startsWith('/session')) {
                      setType('session_suggestion')
                    }
                    else {
                      setType('general')
                    }
                  }}
                  onKeyUpCapture={(e)=> {
                    if(e.key === 'Enter') {
                      // needed to save user last message
                      setChatMessages([...chatMessages, {role: 'user', content: chatUserInput}])
  
                      createChatGptMessage({
                        variables: {
                          "input": {
                            "content": chatUserInput,
                            type
                          }
                        }
                      })
                      setChatUserInput('')
                    
                      setTimeout(() => {
                        scrollDiv.current?.scrollIntoView({ behavior: "smooth" })
                      }, 200);
                    }
                    
                  }}
                  multiline={true}
                />
              </Box>
            </Box>

            {/* dummy div so it could be scrolled to after receiving a message */}
            <div style={{ float:"left", clear: "both" }}
             ref={scrollDiv}>
            </div>
          </Box>
        </Box>
      }
    </Box>
  )
}

export default AiChat

interface ChatMessageProps {
  from?: string
  content?: string
  userImg?: string
  initial?: string
  setType?: (type: string) => void
  createChatGptMessage?: (input: any) => void
}

const ChatMessage = ({from, content, userImg, initial, setType, createChatGptMessage} : ChatMessageProps) => {
  const [showUserInital, setShowUserInital] = React.useState(false)
  const navigate = useNavigate()

  if(!content ) return null
  if(from !== "user" && from !== "assistant") return null

  const UserInital = <div style={{width: '30px', marginRight: '5px', borderRadius: '20px', fontWeight: 800, backgroundColor: 'lightblue', textAlign: 'center', padding: '2px'}}>{initial}</div>
  const isContentJson = content.startsWith('{') && content.endsWith('}')
  let jsonResponse : any = null

  try {
    jsonResponse = isContentJson ? JSON.parse(content) : null
  }
  catch(e) {
    console.log(`error parsing json: ${e}`)
  }

  const returnContent = () => {

    if(!!jsonResponse?.videos) {
      return <VideoPlayer videos={jsonResponse.videos}/>
    }
    else if(!!jsonResponse?.sessionSuggestion) {
      // @ts-ignore
      return <SessionSuggestion 
        session={jsonResponse.sessionSuggestion}
        setType={setType}
        createChatGptMessage={createChatGptMessage}
      />
    }
    else if(!!jsonResponse?.sessionCreated) {      
      const {title, image, id} = jsonResponse.sessionCreated
      
      return <Box sx={{ display: "flex", background: '#c7e6ff', borderRadius: '10px', width: 'max-content', padding: '15px', boxShadow: "4px 4px 10px 0px #dae2e2"}}>
        <img src={image} alt={title} style={{width: '50px', height: '50px', borderRadius: '20px', marginRight: '15px'}}/>
        <Box sx={{display: "flex", flexDirection: "column", gap: '10px'}}>
          <Typography variant="body1">{title}</Typography>
          <Button variant="contained" onClick={()=>{
            navigate(`/p/session/${id}`)
          }}>Go to workout!</Button>
        </Box>  
      </Box>
    }
    else if (content.startsWith('/video')){
      return <><PlayCircleIcon/> {content}</>
    }
    else if (content.startsWith('/session')){
      return <><RunCircleIcon/> {content}</>
    }
    else {
      // @ts-ignore
      return <Box dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked.parse(content))}}></Box>
    }
  }
  

  return <Box sx={{display: 'flex', flexDirection:'column', marginBottom:'20px'}}>
    <style>
    {`
      h1 {
        font-weight: bold;
        font-size: 1.2em;
      }
      h2, h3, h4 {
        font-weight: bold;
        font-size: 1.1em;
      }
      li {
        padding: 2px;
      }
      ol, ul, menu {
      list-style: auto;
      margin: 0;
      padding: 5px;
    }`}
    </style>
  <Box sx={{display: 'flex', alignItems:'center'}}>
    {from === "assistant" ? <img style={{width: '30px', marginRight: '5px', borderRadius: '20px'}} src={AiImgSrc} alt="serious trainer ai assistant"/> : ''}

    {from === "user" && !showUserInital? <img style={{width: '30px', marginRight: '5px', borderRadius: '20px'}} src={userImg} alt="user image" onError={()=>{ setShowUserInital(true)}}/> : ''}

    {from === "user" && showUserInital ? UserInital : ''}

    {content && <Typography variant="body1" fontWeight={600}>{from === "assistant" ? 'AI Coach' : 'You'}</Typography>}
  </Box>

  <Typography sx={{marginLeft: jsonResponse ? '0' : '35px', wordWrap: 'break-word'}} variant="body1" fontSize={13}>
    {returnContent()}
  </Typography>
</Box>
}

interface Videos {
  title: string
  description: string
  video: string
  thumbnail: string
}

interface VideoPlayerProps {
  videos: Videos[]
}

const VideoPlayer = ({videos}: VideoPlayerProps) => {
  const [currentVideo, setCurrentVideo] = React.useState(videos[0]?.video)

  if(!videos?.length) return <p>Error loading video</p>

  const imgStyle = {
    width: '50px', 
    height: '50px', 
    cursor: 'pointer', 
    borderRadius: '5px'
  }
  const imgStyleActive = {
    ...imgStyle,
    border: '2px solid red'
  }
  
  return <Box sx={{}}>
    <Box>
      <iframe 
        width="100%" 
        height="250" 
        src={currentVideo} 
        style={{maxWidth: "350px", marginTop: "12px"}} 
        title="Serious trainer player" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen 
      />
    </Box>
    <Box sx={{display: 'flex', gap: '10px', marginTop: '10px', flexWrap: 'wrap'}}>
      {videos.map((vid, i) => {
        return <Box key={vid.title + i} style={vid.video === currentVideo ? imgStyleActive : imgStyle }>
          <img src={vid.thumbnail} alt={vid.title} style={{width: '46px', height: '46px'}} onClick={()=>{setCurrentVideo(vid.video)}}/>
        </Box>
      })}
    </Box>
</Box>
}

interface SessionSuggestion {
  session: {
    title: string
    description: string
    duration: number
    equipment: string
    skillLevel: string
    targetMuscles: string[]
    groups: {
      title: string
      exercises: {
        exercise: {
          title: string
          type: string
        }
        instruction: string
      }[]
      reps: string
    }[]
  }
  setType: ((type: string) => void) | undefined
  createChatGptMessage: ((input: any) => void) | undefined
}
  

const SessionSuggestion = ({session, setType, createChatGptMessage} : SessionSuggestion) => {

return <Box>
  <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
    <Box>
      <h1 style={{margin: '10px 0'}}>{session?.title}</h1>
      <p>{session?.description}</p>
    </Box>
    <Box>
      <p><b>Duration:</b> {session?.duration} minutes</p>
      <p><b>Equipment:</b> {session?.equipment}</p>
      <p><b>Skill Level:</b> {session?.skillLevel}</p>
      <p><b>Target Muscles:</b> {session?.targetMuscles?.join(', ')}</p>
    </Box>
    <Box sx={{marginTop: '10px'}}>
      {session?.groups?.map((group : any) => {
      return <Box sx={{marginBottom: '5px'}}>
        <h4>{group.title} <span>(reps: {group.reps})</span></h4>
        <ul>
          {group.exercises.map((exercise : any) => {
            return <li>
              <p>{exercise.exercise.title}</p>
              <p>{exercise.instruction}</p>
            </li>
          })}
        </ul>
      </Box>
    })}
    </Box>
  </Box>
  {/* create 3 ctas inline with a gap of 10px, create, iterate and delete */}
  <Box sx={{display: 'flex', gap: '10px', marginTop: '10px'}}>
    <Button variant="contained" color="success" onClick={()=>{
      if (setType && createChatGptMessage) {
        setType('session_creator')
        createChatGptMessage({
          variables: {
            "input": {
              "content": JSON.stringify(session),
              "type": 'session_creator'
            }
          }
        })
      }
    }}>Create session</Button>
    {/* <Button variant="outlined" color="secondary">Iterate</Button>
    <Button variant="outlined" color="error">Delete</Button> */}
  </Box>
</Box>
}