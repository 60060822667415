import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/joy/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {getCurrentUser} from '../../services'
import Layout from "../organisms/Layout";
import Loader from "../atoms/Loader";
import Toast from '../molecules/Toast'
import ShareIcon from '@mui/icons-material/Share';


export const QUERY_MY_PROGRAM = gql`
  query Programs($input: ProgramInput) {
    programs(input: $input) {
      id
      title
      description
      image
      createdAt
      sessions {
        id
        title
        skillLevel
        description
        duration
        image
      }
    }
  }
`

export const QUERY_PROGRAM_PROGRESS = gql`
  query ProgramProgress($input: ProgramProgressInput) {
    programProgress(input: $input) {
      completionCriteria
      modifiedAt
      completed
      createdAt
      id
      programId
      userId
      sessionsCompleted
    }
  }
`

const Program = ({ })=> {
  const [toast, setToast] = React.useState<{open: boolean, message: string, severity: 'error' | 'success'}>({
    open: false, 
    message: '', 
    severity: 'success'
  })
  const { id } = useParams()
  const { data: programData, loading: programLoading, error: programError } = useQuery(QUERY_MY_PROGRAM, {
    variables: { "input": { id } }
  })
  const { data: progressData, loading: progressLoading, error: progressError } = useQuery(QUERY_PROGRAM_PROGRESS, {
    variables: { "input": {
      "userId": getCurrentUser()?.id,
      "programId": programData?.programs[0]?.id || null,
    }}
  })
  const programProgress = progressData?.programProgress[0] 
  const isUserLoggedIn = !!getCurrentUser()

  React.useEffect(() => {
    document.title = 'Serious Trainer | Program';
  }, []);

  React.useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    let title = document.querySelector("meta[name='title']");
    let description = document.querySelector("meta[name='description']");

    if (!link) {
      link = document.createElement('link');
      // @ts-ignore
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if(programData?.programs[0]?.image){
      // @ts-ignore
      link.href = programData?.programs[0]?.image;
    }
    if(programData?.programs[0]?.title){
      // @ts-ignore
      title?.setAttribute("content", programData?.programs[0]?.title);
      description?.setAttribute("content", programData?.programs[0]?.title);
    }
  }, [programData]);

  const navigate = useNavigate()

  const handleSessionClick = (session : any)=>{ 
    navigate(`/p/session/${session.id}?programId=${programData?.programs[0]?.id}`)}


  // if(programLoading) ( <p>Loading</p> )

  if (programError) ( <p>programError</p> )

  return (
    <Layout>
      <Toast toast={toast} setToast={setToast} />
      <Box sx={{background: 'whitesmoke', height:'100%'}}>
        <Box>
          {programLoading ? <Loader /> : null}

          <Box sx={{display: 'flex', height: '70vh', backgroundImage:`url(${programData?.programs[0]?.image})`, backgroundSize: 'cover', backgroundColor:'black', flexDirection: 'column', justifyContent: 'end', padding: '12px 24px'}}>
            <Typography variant="h4" component="div" sx={{color: 'white',fontWeight: 'bold'}}>
              {programData?.programs[0]?.title}
            </Typography>
            <Box sx={{display: "flex"}}>
              <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#ff3c4b"}} startDecorator={<ScatterPlotOutlinedIcon/>} >
                {programData?.programs[0]?.sessions && programData?.programs[0]?.sessions.length > 1 ? `${programData?.programs[0]?.sessions.length} Sessions` : `${programData?.programs[0]?.sessions.length} Session`}
              </Chip>
              
              {/* create share button to copy link from address bar to clip board  */}
              <Chip color="primary" style={{backgroundColor:"#ffd0d3", color:"#000", marginLeft: '12px'}}
              onClick={()=>{ 
                navigator.clipboard.writeText(window.location.href)
                
                setToast({open: true, message: 'Link copied to clipboard', severity: 'success'})
              }}
              startDecorator={<ShareIcon/>}
              >
                Share
              </Chip>
            </Box>
          </Box>
  
          <Typography sx={{margin: ' 24px'}} variant="body1" component="div">
            {programData?.programs[0]?.description}
          </Typography>
  
          <Box sx={{ margin: '24px' }}>
            <Typography  sx={{margin: '12px 0'}} variant="h5" component="div">
              Progress
            </Typography>
            <LinearProgress variant="buffer" valueBuffer={100} value={programProgress ? programProgress?.completed ? 100 
            : (programProgress?.sessionsCompleted.length/ programData?.programs[0]?.sessions?.length) * 100
            : 0 } />
            <Typography  sx={{margin: '12px 0'}} variant="body1" component="div">
              {programProgress ? programProgress?.completed ? 'All' 
              : programProgress?.sessionsCompleted.length 
              : 0 } session{programProgress?.sessionsCompleted?.length > 1 ? 's' : ''} completed
            </Typography>
          </Box>
  
          <Box sx={{ margin: '24px' }}>
            <Typography  sx={{margin: '12px 0'}} variant="h5" component="div">
              Sessions
            </Typography>
            
            {programLoading ? [0,1].map(() => <Box sx={{height: '60px', width: '100%', backgroundColor: 'lightgrey', marginBottom: '10px'}} />) : null}
            
            {programData?.programs[0]?.sessions && programData?.programs[0]?.sessions.map((session: any) => {
              const sessionCompleted = programProgress?.sessionsCompleted.includes(session.id)
  
              return <Box key={session.id} sx={{display: 'flex', border: 'solid lightgrey 1px', borderRadius: '4px', marginBottom: '12px', cursor:'pointer'}}
              onClick={()=>{handleSessionClick(session)}}>
              <div style={{height: '50px', display: 'flex', marginRight: '12px', backgroundColor: 'lightgrey', width: '50px'}}>
                {session?.image && <img alt="session-img" src={session?.image}/>}
              </div>
              <div style={{display: 'flex', flexDirection: 'column', padding: '6px 12px 0'}}>
                <Typography variant="body1" component="div" fontWeight="bold" fontSize="14px">
                  {session.title}
                </Typography>
                <Typography variant="body1" component="div" fontSize="14px" sx={{textTransform: 'lowercase'}}>
                  {session.duration} mins, skill: {session.skillLevel}
                </Typography>
              </div>
              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center',  paddingRight: '12px'}}>
                { sessionCompleted ? <CheckCircleIcon color="success"/> : null}
                <PlayCircleFilledOutlinedIcon/>
              </div>
            </Box>
            })}
            
          </Box>
  
          {/* {isUserLoggedIn ? <Box sx={{display: 'flex', justifyContent:'center', padding: '0 24px 24px'}}><Button variant="contained" fullWidth={true}>Start</Button></Box> : null} */}
  
        </Box>
      </Box>
    </Layout>
  )
}

export default Program